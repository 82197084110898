declare global {
  interface Window {
    handleQuestionClick: (question: string) => void;
  }
}

import { useUser } from 'contexts/UserContext';
import React, { useState, useEffect } from 'react';
import { GptApi } from 'services/api/gpt';
import Typewriter from 'typewriter-effect';
import { Copy, ThumbsUp } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { User } from 'src/types/User/user';
import LeniQDataTable from '../LeniQDataTable';
import LeniQProgressMessages from '../LeniQProgressMessages';
import LeniQFeedbackModal from 'components/Modals/LeniQFeedbackModal';
import Image from 'next/image';
import LeniQReasoningComponent from '../LeniQReasoningComponent';

interface LeniQAnswerProps {
  question: string;
  answer: any;
  hasError: boolean;
  onRenderedAnimationCompleted: () => void;
  onQuestionClick: (question: string) => void;
}

const LeniQAnswerComponent: React.FC<LeniQAnswerProps> = ({
  answer,
  hasError,
  onRenderedAnimationCompleted,
  onQuestionClick,
}) => {
  const { user: currentUser } = useUser();
  const [hasRated, setHasRated] = useState(false);
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  const { toast } = useToast();

  const copyToClipboard = (text: string) => {
    try {
      navigator.clipboard.writeText(cleanText(text));
      toast({
        description: 'Copied to clipboard!',
        variant: 'success',
        duration: 2000,
      });
    } catch (error) {
      toast({
        description: 'Oops! Something went wrong.',
        variant: 'error',
        duration: 2000,
      });
    }
  };

  const handlePositiveFeedback = async (
    questionId: string,
    user: User | null,
    setHasRated: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!user) return;
    try {
      await GptApi.giveFeedback({ success: true, questionId, user });
      toast({
        variant: 'success',
        description: 'Your feedback has been successfully submitted',
        duration: 3000,
      });
      setHasRated(true);
    } catch (error) {
      console.log(error);
      toast({
        variant: 'error',
        description: 'Something went wrong when giving your feedback - Please try again later',
        duration: 3000,
      });
      setHasRated(false);
    }
  };

  const cleanText = (text: string): string => {
    // First remove all HTML/XML tags except <question> tags
    const withoutHtml = text.replace(/<(?!\/?question\b)[^>]+>/g, '');
    // Then remove <question> tags
    const withoutQuestionTags = withoutHtml.replace(/<\/?question>/g, '');
    // Remove extra whitespace and normalize line breaks
    return withoutQuestionTags.replace(/\s+/g, ' ').replace(/\n\s*/g, '\n').trim();
  };

  const processAnswer = (text: string) => {
    if (!isTypingComplete) {
      return text.replace(/<question>.*?<\/question>/g, '');
    }

    const parts = text.split(/(<question>.*?<\/question>)/);
    const formattedText = parts
      .map(part => {
        if (part.startsWith('<question>') && part.endsWith('</question>')) {
          const question = part.replace(/<\/?question>/g, '');
          return `<a class="text-blue-800 ml-2 my-2 flex cursor-pointer font-bold hover:text-blue-900 hover:underline transition-colors duration-200" onclick="window.handleQuestionClick('${question.replace(/'/g, "\\'")}')">${question}</a>`;
        }
        return part;
      })
      .join('');

    // Ensure paragraphs are correctly inserted during typing
    return formattedText.replace(/\n\n/g, '<br /><br />');
  };

  useEffect(() => {
    window.handleQuestionClick = (question: string) => {
      onQuestionClick(question);
    };
    return () => {
      (window as any).handleQuestionClick = undefined;
    };
  }, [onQuestionClick]);

  return (
    <div className="flex flex-row ">
      <div
        className="flex items-start rounded-full mr-2 border-gray-300 border w-8 h-8  items-center justify-center my-4 mt-5 py-2   bg-blue-50 "
        style={{ flex: '0 0 32px' }}>
        <Image
          src="/static/icons/leniq-circle.svg"
          width={20}
          height={20}
          className={`${!answer && !hasError ? 'animate-spin' : ''}`}
          alt="Sage Plus Icon"
        />
      </div>

      {!answer && !hasError && (
        <div className="flex flex-col items-start my-4 py-2 text-sm">
          <LeniQProgressMessages />
        </div>
      )}
      <div className="flex flex-col items-start my-4 py-2 text-sm">
        {answer && !hasError && answer.summary && (
          <div className="answer-container">
            <div style={{ display: isTypingComplete ? 'none' : 'block' }}>
              <Typewriter
                options={{
                  delay: 30,
                  cursor: '',
                }}
                onInit={typewriter => {
                  typewriter
                    .typeString(processAnswer(answer.summary).replace(/\n/g, '<br />')) // Ensures new lines appear during typing
                    .callFunction(() => {
                      setIsTypingComplete(true);
                      if (onRenderedAnimationCompleted) {
                        onRenderedAnimationCompleted();
                      }
                    })
                    .start();
                }}
              />
            </div>
            {isTypingComplete && (
              <div
                className="answer-content"
                dangerouslySetInnerHTML={{
                  __html: processAnswer(answer.summary),
                }}
              />
            )}
          </div>
        )}
        {hasError && (
          <div className="bg-red-100 w-full p-2 flex flex-col items-start gap-2 rounded-xl transition-all text-start">
            Something went wrong. If the issue persist, please contact us through our help center.
          </div>
        )}
        {answer && !hasError && answer?.tableData?.length > 0 && <LeniQDataTable data={answer.tableData} />}
        {answer?.reasoning && <LeniQReasoningComponent reasoning={answer?.reasoning} />}
        {answer && (answer.summary || answer?.tableData?.length > 0) && (
          <div className="flex items-center gap-3 mt-2">
            <ThumbsUp
              height={14}
              width={14}
              className={`cursor-pointer ${hasRated ? 'pointer-events-none text-gray-300' : 'text-gray-400'}`}
              onClick={() => handlePositiveFeedback(answer?.questionId as string, currentUser, setHasRated)}
            />
            <LeniQFeedbackModal
              questionId={answer?.questionId as string}
              setHasRated={setHasRated}
              hasRated={hasRated}
            />
            <Copy
              height={14}
              width={14}
              className="text-gray-400 cursor-pointer"
              onClick={() => copyToClipboard(answer?.summary as string)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LeniQAnswerComponent;
