// import { Maximize2, Minimize2, Shield, X } from 'lucide-react';
import { X, Maximize2, Minimize2 } from 'lucide-react';
import Image from 'next/image';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import useLeniQContext from 'utils/hooks/useLeniQContext';
import LeniQChat from 'components/LeniQ/LeniQChat';
import LeniQHistory from 'components/LeniQ/LeniQHistory';
// import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

enum LENI_Q_TABS {
  HISTORY = 'History',
  NEW_CHAT = 'New Chat',
}

const LeniQContainer: React.FC = () => {
  const [activeTab, setActiveTab] = useState<LENI_Q_TABS>(LENI_Q_TABS.NEW_CHAT);
  // const [maximized, setMaximized] = useState(false);
  const {
    // state: { question: questionAsked },
    state: { leniQDrawerFullscreen },
    dispatch: leniQDispatch,
  } = useLeniQContext();

  const handleQuestionClick = (question: string) => {
    if (activeTab !== LENI_Q_TABS.NEW_CHAT) setActiveTab(LENI_Q_TABS.NEW_CHAT);
    leniQDispatch({
      type: 'SET_QUESTION',
      payload: question,
    });
  };

  const closeLeniQ = () => {
    leniQDispatch({
      type: 'SET_LENIQ_DRAWER_OPEN',
      payload: false,
    });
    leniQDispatch({
      type: 'SET_QUESTION',
      payload: '',
    });
  };

  const toggleLeniQFullscreen = () => {
    leniQDispatch({
      type: 'TOGGLE_LENIQ_DRAWER_FULLSCREEN',
      payload: true,
    });
  };

  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ duration: 0.3, ease: 'linear' }}
      className={`flex flex-col bg-white-50 border-[1px] border-gray-100 rounded-3xl shadow-lg ${leniQDrawerFullscreen ? `w-full` : `w-[350px] col-start-3 col-end-4`} row-start-1 row-end-13 relative `}>
      <div className="flex items-center gap-5 border-b-[1px] border-gray-100 p-4 shadow-md rounded-t-3xl">
        <div className="flex items-center gap-1">
          <div className="flex items-center">
            <Image src="/static/icons/leniq-circle.svg" width={25} height={35} alt="Sage Plus Icon" />
          </div>
          <h3 className="text-lg font-bold text-gray-700 leading-none">LeniQ</h3>
        </div>

        <div className="flex items-center gap-2 ml-auto">
          {/*<span
            className={`${activeTab === LENI_Q_TABS.NEW_CHAT ? 'text-gray-800' : 'text-gray-400'} cursor-pointer`}
            onClick={() => {
              setActiveTab(LENI_Q_TABS.NEW_CHAT);
              leniQDispatch({
                type: 'SET_QUESTION',
                payload: '',
              });
            }}>
            New Chat
          </span>
          <span
            className={`${activeTab === LENI_Q_TABS.HISTORY ? 'text-gray-800' : 'text-gray-400'} cursor-pointer`}
            onClick={() => {
              setActiveTab(LENI_Q_TABS.HISTORY);
              leniQDispatch({
                type: 'SET_QUESTION',
                payload: '',
              });
            }}>
            History
          </span>*/}
          {/* TODO - Add maximize/minimize later */}
          {/* <TooltipProvider>
            {maximized ? (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Minimize2
                    height={15}
                    width={15}
                    className="cursor-pointer text-gray-400"
                    onClick={() => setMaximized(false)}
                  />
                </TooltipTrigger>
                <TooltipContent
                  side="bottom"
                  align="end"
                  sideOffset={8}
                  className="z-[1000] bg-white-50 p-3 border-[1px] border-gray-50 shadow-xl rounded-lg">
                  <p className="text-gray-400">Minimize View</p>
                </TooltipContent>
              </Tooltip>
            ) : (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Maximize2
                    height={15}
                    width={15}
                    className="cursor-pointer text-gray-400"
                    onClick={() => setMaximized(true)}
                  />
                </TooltipTrigger>
                <TooltipContent
                  side="bottom"
                  align="end"
                  sideOffset={8}
                  className="z-[1000] bg-white-50 p-3 border-[1px] border-gray-50 shadow-xl rounded-lg">
                  <p className="text-gray-400">Maximize View</p>
                </TooltipContent>
              </Tooltip>
            )}
          </TooltipProvider> */}
          {!leniQDrawerFullscreen && (
            <Maximize2 height={20} width={20} onClick={toggleLeniQFullscreen} className="cursor-pointer rotate-90" />
          )}
          {leniQDrawerFullscreen && (
            <Minimize2 height={20} width={20} onClick={toggleLeniQFullscreen} className="cursor-pointer rotate-90" />
          )}
          <X height={20} width={20} onClick={closeLeniQ} className="cursor-pointer" />
        </div>
      </div>
      {activeTab === LENI_Q_TABS.NEW_CHAT && (
        <LeniQChat handleQuestionClick={handleQuestionClick} isFullScreen={leniQDrawerFullscreen} />
      )}
      {activeTab === LENI_Q_TABS.HISTORY && <LeniQHistory handleQuestionClick={handleQuestionClick} />}
    </motion.div>
  );
};

export default LeniQContainer;
