import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { ReportBuilderKPIMap } from 'data/reportBuilderData';

// Define the type for the data prop
interface ChartData {
  [key: string]: string | number;
}

interface ReportBuilderLineChartProps {
  id: string;
  data: ChartData[];
}

const getLineColor = (id: string) => {
  switch (id) {
    case ReportBuilderKPIMap.T6_DAILY_OPEN_WORK_ORDERS:
      return '#ECD36C';
    case ReportBuilderKPIMap.T12_MONTHLY_COMPLETED_WORK_ORDERS:
      return '#ECD36C';
    case ReportBuilderKPIMap.T12_MONTHLY_CREATED_WORK_ORDERS:
      return '#ECD36C';
    case ReportBuilderKPIMap.T6_DAILY_SQFT_OCCUPANCY:
      return '#E15759';
    case ReportBuilderKPIMap.T6_DAILY_ECONOMIC_OCCUPANCY:
      return '#59A14F';
    case ReportBuilderKPIMap.T12_AVG_DAYS_TO_COMPLETE_WORK_ORDER:
      return '#F1CE63';
    case ReportBuilderKPIMap.T6_DAILY_OCCUPANCY_RATE:
      return '#EBD36D';
    default:
      return '#000'; // Default color if id does not match
  }
};

const formatPercentageLabel = (value: number) => {
  return `${value}%`;
};

const formatNumberLabel = (value: number) => {
  return value.toLocaleString('en-US');
};

const ReportBuilderLineChart: React.FC<ReportBuilderLineChartProps> = ({ id, data }) => {
  // Empty data should be handled in outer component ReportBuilderResizableItem for debugging purposes
  // Guard clause should be if Tableau returned an error (status code 429, etc)
  if (!Array.isArray(data) || data.length === 0) {
    return;
  }
  const xLabel = Object.keys(data[0])[0];
  const yLabel = Object.keys(data[0])[1];

  // Calculate the min and max values for the y-axis
  const yValues = data.map(item => item[yLabel] as number);
  const yMin = Math.floor(Math.min(...yValues) - 1);
  const yMax = Math.floor(Math.max(...yValues) + 1);

  const isPercentageValue =
    id === ReportBuilderKPIMap.T6_DAILY_ECONOMIC_OCCUPANCY ||
    id === ReportBuilderKPIMap.T6_DAILY_OCCUPANCY_RATE ||
    id === ReportBuilderKPIMap.T6_DAILY_SQFT_OCCUPANCY;

  const isDailyKPI =
    id === ReportBuilderKPIMap.T6_DAILY_SQFT_OCCUPANCY ||
    id === ReportBuilderKPIMap.T6_DAILY_ECONOMIC_OCCUPANCY ||
    id === ReportBuilderKPIMap.T6_DAILY_OCCUPANCY_RATE ||
    id === ReportBuilderKPIMap.T6_DAILY_OPEN_WORK_ORDERS;

  return (
    <ResponsiveContainer width="100%" height="80%">
      <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey={xLabel} />
        <YAxis
          domain={isPercentageValue ? [yMin, yMax] : undefined}
          tickFormatter={isPercentageValue ? formatPercentageLabel : formatNumberLabel}
        />
        <Tooltip formatter={isPercentageValue ? formatPercentageLabel : formatNumberLabel} />
        <Line type="monotone" dataKey={yLabel} stroke={getLineColor(id)} dot={false}>
          {!isDailyKPI ? (
            <LabelList dataKey={yLabel} formatter={formatNumberLabel} />
          ) : (
            <LabelList
              dataKey={yLabel}
              content={props => {
                const { x, y, value, index } = props;
                const isLastPoint = index === data.length - 1;
                return isLastPoint && x !== undefined && y !== undefined ? (
                  <text x={x} y={typeof y === 'number' ? y - 10 : 0} fill="#000" textAnchor="middle" fontSize="14px">
                    {id === ReportBuilderKPIMap.T6_DAILY_OPEN_WORK_ORDERS
                      ? formatNumberLabel(value as number)
                      : formatPercentageLabel(value as number)}
                  </text>
                ) : null;
              }}
            />
          )}
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ReportBuilderLineChart;
