import ReportBuilderShareContainer from 'containers/ReportBuilder/ReportBuilderShare';

const ReportPageLayout: React.FC = () => {
  return (
    <main>
      <ReportBuilderShareContainer downloadPage={false} />
    </main>
  );
};

export default ReportPageLayout;
