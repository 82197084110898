import { ReportBuilderKPIMap } from 'data/reportBuilderData';
import moment from 'moment';
export type DataEntry = Array<Record<string, string | number | null>>;

const transformDataForNext10WKPIs = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return data.reduce<Array<Record<string, string | number>>>((acc, curr) => {
    const week = moment(curr['Week'] as string).format('MM/DD'); // Format the week using moment.js
    const measureName = (curr['Measure Names'] as string).trim();
    const measureValue = curr['Measure Values'] as number;

    // Check if an entry for the current week already exists
    let existingEntry = acc.find(entry => entry['Week'] === week);

    if (!existingEntry) {
      // If no entry exists, create a new one with "Week" as the first key
      existingEntry = { Week: week };
      acc.push(existingEntry);
    }

    // Add the measure value to the corresponding key in the existing entry
    existingEntry[measureName] = measureValue;

    // Reorder keys to ensure "Week" is always first
    const reorderedEntry: Record<string, string | number> = { Week: existingEntry.Week };
    Object.keys(existingEntry)
      .filter(key => key !== 'Week') // Exclude "Week" for reordering
      .forEach(key => {
        reorderedEntry[key] = existingEntry[key];
      });

    // Replace the original entry with the reordered entry
    acc[acc.indexOf(existingEntry)] = reorderedEntry;

    return acc;
  }, []);
};

const transformDataForT12LeaseTradeOutsByMonth = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  const transformedData = data.reduce<Array<Record<string, string | number>>>((acc, curr) => {
    const rawMonth = curr['Month of stats_month'] as string; // Preserve raw date for sorting
    const formattedMonth = moment(rawMonth).format('MM-DD');
    const measureName = (curr['Measure Names'] as string).trim();
    const measureValue = curr['Measure Values'] as number;

    // Check if an entry for the current month already exists
    let existingEntry = acc.find(entry => entry['rawMonth'] === rawMonth);

    if (!existingEntry) {
      // If no entry exists, create a new one with "month" as the first key
      existingEntry = { month: formattedMonth, rawMonth };
      acc.push(existingEntry);
    }

    // Add the measure value to the corresponding key in the existing entry
    existingEntry[measureName === 'num_trade_outs' ? 'Trade Outs' : measureName] = measureValue;

    // Reorder keys to ensure "month" is always first
    const reorderedEntry: Record<string, string | number> = { month: existingEntry.month };
    Object.keys(existingEntry)
      .filter(key => key !== 'month' && key !== 'rawMonth') // Exclude "month" and "rawMonth" for reordering
      .forEach(key => {
        reorderedEntry[key] = existingEntry[key];
      });

    // Replace the original entry with the reordered entry
    acc[acc.indexOf(existingEntry)] = reorderedEntry;

    return acc;
  }, []);

  // Step 2: Sort the transformed data by rawMonth
  return (
    transformedData
      .sort((a, b) => new Date(a.rawMonth as string).getTime() - new Date(b.rawMonth as string).getTime())
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ rawMonth, ...rest }) => rest)
  ); // Remove rawMonth from the final output
};

const transformDataForT6ResidentActivity = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  // Transform the data
  const transformedData = data.reduce<Array<Record<string, string | number>>>((acc, curr) => {
    const rawMonth = curr['Month of stats_month'] as string; // Keep raw date for sorting
    const formattedMonth = moment(rawMonth).format('MMM-YY'); // Format the month to MMM-YY
    const activityType = (curr['Activity Type'] as string).trim();
    const measureValue = curr['Measure Values'] as number;

    // Check if an entry for the current month already exists
    let existingEntry = acc.find(entry => entry['Month'] === formattedMonth);

    if (!existingEntry) {
      // If no entry exists, create a new one with "Month" as the first key
      existingEntry = { Month: formattedMonth, rawMonth };
      acc.push(existingEntry);
    }

    // Add the measure value to the corresponding key in the existing entry
    existingEntry[activityType] = measureValue;

    return acc;
  }, []);

  // Sort the transformed data by rawMonth and remove rawMonth key
  return (
    transformedData
      .sort((a, b) => new Date(a.rawMonth as string).getTime() - new Date(b.rawMonth as string).getTime())
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ rawMonth, ...rest }) => rest)
  ); // Remove rawMonth from the final output
};

const transformDataForT6MonthlyLeaseRenewalRates = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  // Transform the data
  const transformedData = data.map(item => {
    const rawMonth = item['Month'] as string; // Keep raw date for sorting
    const formattedMonth = moment(rawMonth).format('MMM-YY'); // Format the month to MMM-YY
    const measureName = (item['Measure Names'] as string).trim();
    const measureValue = (item['Measure Values'] as number) * 100; // Convert to percentage

    return {
      Month: formattedMonth,
      rawMonth, // Keep for sorting
      [measureName]: measureValue.toFixed(2), // Format to 2 decimal places as a string
    };
  });

  // Sort the transformed data by rawMonth and remove rawMonth key
  return (
    transformedData
      .sort((a, b) => new Date(a.rawMonth as string).getTime() - new Date(b.rawMonth as string).getTime())
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ rawMonth, ...rest }) => rest)
  ); // Remove rawMonth from the final output
};

const transformDataForT12AvgIncrementalLeaseValue = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return data
    .map(entry => {
      const formattedMonth = moment(entry['Month'] as string).format('MM-YY'); // Format Month to MM-YY
      const measureName = (entry['Measure Names'] as string).trim(); // Extract Measure Names
      const measureValue = parseFloat((entry['Measure Values'] as number).toFixed(2)); // Format Measure Values to 2 decimal points

      return {
        Month: formattedMonth, // Rename and format Month
        [measureName]: measureValue, // Create key-value pair for Measure Names and Measure Values
      };
    })
    .sort((a, b) => {
      // Sort by the original Month value in chronological order
      const originalMonthA = moment(a['Month'], 'MM-YY').toDate();
      const originalMonthB = moment(b['Month'], 'MM-YY').toDate();
      return originalMonthA.getTime() - originalMonthB.getTime();
    });
};

const transformDataForT6AverageGainLossOnTradeOut = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  // Transform the data
  const transformedData = data.map(item => {
    const rawMonth = item['Month'] as string; // Keep raw date for sorting
    const formattedMonth = moment(rawMonth).format('MMM-YY'); // Format the month to MMM-YY
    const measureName = (item['Measure Names'] as string).trim();
    const measureValue = (item['Measure Values'] as number) * 100; // Convert to percentage

    return {
      Month: formattedMonth,
      rawMonth, // Keep for sorting
      [measureName]: measureValue.toFixed(2), // Format to 2 decimal places as a string
    };
  });

  // Sort the transformed data by rawMonth and remove rawMonth key
  return (
    transformedData
      .sort((a, b) => new Date(a.rawMonth as string).getTime() - new Date(b.rawMonth as string).getTime())
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ rawMonth, ...rest }) => rest)
  ); // Remove rawMonth from the final output
};

const transformDataForT6DailyOpenWorkOrders = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  const transformedData = data.map(curr => {
    const rawDate = (curr['stats_date'] as string).trim();
    const formattedDate = moment(rawDate).format('MMM-DD'); // Format the date to MMM-DD
    const workOrders = curr['Measure Values'] as number;

    return {
      rawDate, // Keep rawDate for sorting
      Date: formattedDate, // Formatted date
      'Work Orders': workOrders, // Transformed key-value pair
    };
  });

  // Sort the data by rawDate in ascending order
  return (
    transformedData
      .sort((a, b) => new Date(a.rawDate).getTime() - new Date(b.rawDate).getTime())
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ rawDate, ...rest }) => rest)
  ); // Remove rawDate from the final output
};

const transformDataForT12MonthlyCreatedWorkOrders = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  // Transform the data
  const transformedData = data.map(item => {
    const rawMonth = item['Month of diwo_created_date'] as string; // Keep raw date for sorting
    const formattedMonth = moment(rawMonth, 'MMMM YYYY').format('MMM-YY'); // Format the month to MMM-YY
    const workOrders = item['Measure Values'] as number;

    return {
      Month: formattedMonth, // Rename and format the month
      rawMonth, // Keep raw month for sorting
      'Work Orders': workOrders, // Rename 'Measure Values' to 'Work Orders'
    };
  });

  // Sort the transformed data by rawMonth and remove rawMonth key
  return (
    transformedData
      .sort(
        (a, b) =>
          moment(a.rawMonth, 'MMMM YYYY').toDate().getTime() - moment(b.rawMonth, 'MMMM YYYY').toDate().getTime()
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ rawMonth, ...rest }) => rest)
  ); // Remove rawMonth from the final output
};

const transformDataForT12MonthlyCompletedWorkOrders = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  // Transform the data
  const transformedData = data.map(item => {
    const rawMonth = item['Month of diwo_completion_date'] as string; // Keep raw date for sorting
    const formattedMonth = moment(rawMonth, 'MMMM YYYY').format('MMM-YY'); // Format the month to MMM-YY
    const workOrders = item['Measure Values'] as number;

    return {
      Month: formattedMonth, // Rename and format the month
      rawMonth, // Keep raw month for sorting
      'Work Orders': workOrders, // Rename 'Measure Values' to 'Work Orders'
    };
  });

  // Sort the transformed data by rawMonth and remove rawMonth key
  return (
    transformedData
      .sort(
        (a, b) =>
          moment(a.rawMonth, 'MMMM YYYY').toDate().getTime() - moment(b.rawMonth, 'MMMM YYYY').toDate().getTime()
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ rawMonth, ...rest }) => rest)
  ); // Remove rawMonth from the final output
};

const transformDataForT6DailyOccupancyRate = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return data.reduce<Array<Record<string, string | number>>>((acc, curr) => {
    const rawWeek = curr['Week'] as string;
    const formattedWeek = moment(rawWeek).format('MMM-DD'); // Format the week as MMM-DD
    const measureName = (curr['Measure Names'] as string).trim();
    const measureValue = ((curr['Measure Values'] as number) * 100).toFixed(2); // Convert to percentage and format to 2 decimals

    // Check if an entry for the current week already exists
    let existingEntry = acc.find(entry => entry['Week'] === formattedWeek);

    if (!existingEntry) {
      // If no entry exists, create a new one
      existingEntry = { Week: formattedWeek };
      acc.push(existingEntry);
    }

    // Add the measure name and value as a key-value pair
    existingEntry[measureName] = measureValue;

    return acc;
  }, []);
};

const transformDataForT6DailyEconomicOccupancy = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return data.reduce<Array<Record<string, string | number>>>((acc, curr) => {
    const rawDate = curr['rfcl_date'] as string; // Original date for processing
    const formattedDate = moment(rawDate).format('MMM-DD'); // Format date to MMM-DD
    const measureName = curr['Measure Names'] as string; // Use Measure Names as key
    const measureValue = ((curr['Measure Values'] as number) * 100).toFixed(2); // Convert to percentage and format to 2 decimals

    // Create new object with formatted date and key-value pair from Measure Names and Measure Values
    const transformedEntry: Record<string, string | number> = {
      Date: formattedDate,
      [measureName]: measureValue,
    };

    acc.push(transformedEntry);
    return acc;
  }, []);
};

const transformDataForT12AvgDaysToCompleteWorkOrder = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  // Transform the data
  const transformedData = data.map(item => {
    const rawWeek = item['Week'] as string; // Original date for sorting
    const formattedWeek = moment(rawWeek).format('MMM-DD'); // Format date to MMM-DD
    const measureName = item['Measure Names'] as string; // Use Measure Names as key
    const measureValue = (item['Measure Values'] as number).toFixed(2); // Format to 2 decimal places

    return {
      Week: formattedWeek, // Rename and format the week
      rawWeek, // Keep raw week for sorting
      [measureName]: parseFloat(measureValue), // Use Measure Names as key and formatted value
    };
  });

  // Sort the transformed data by rawWeek and remove rawWeek key
  return (
    transformedData
      .sort((a, b) => new Date(a.rawWeek as string).getTime() - new Date(b.rawWeek as string).getTime())
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ rawWeek, ...rest }) => rest)
  ); // Remove rawWeek from the final output
};

const transformDataForInPlaceRent = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return data.reduce<Array<Record<string, number | string>>>((acc, curr) => {
    const numBedrooms = (curr['Num Bedrooms'] as string).trim().replace(',', '');
    const measureName = (curr['Measure Names'] as string).trim();
    const measureValue = curr['Measure Values'] as number;

    // Check if an entry for the current number of bedrooms already exists
    let existingEntry = acc.find(entry => entry['Num Bedrooms'] === numBedrooms);

    if (!existingEntry) {
      // If no entry exists, create a new one
      existingEntry = { 'Num Bedrooms': numBedrooms };
      acc.push(existingEntry);
    }

    // Add the measure value to the corresponding key in the existing entry
    if (measureName === 'AVG In-Place,') {
      existingEntry['Avg. In-Place Rent'] = measureValue;
    } else if (measureName === 'MEDIAN In-Place,') {
      existingEntry['Median In-Place Rent'] = measureValue;
    } else if (measureName === 'AVG. Market Rent,') {
      existingEntry['Avg. Market Rent'] = measureValue;
    }

    return acc;
  }, []);
};

const transformDataForT12WorkOrderPriorityTrends = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  const groupedData: Record<string, Record<string, string | number>> = {};
  data.forEach(entry => {
    const rawMonth = (entry['Month of diwo_created_date'] as string)?.trim(); // Raw date for sorting
    const formattedMonth = moment(rawMonth).format('MMM-YY'); // Format to MMM-YY
    const priority = (entry['Priority'] as string)?.replace(',', '').trim() || 'Other'; // Remove commas and trim
    const count = entry['Count of Custom SQL Query'];

    if (!rawMonth) {
      return; // Skip if the month is missing
    }

    // Initialize month group if not already created
    if (!groupedData[rawMonth]) {
      groupedData[rawMonth] = { Month: formattedMonth };
    }

    // Accumulate counts for the priority
    if (count === undefined || count === null) {
      const numericPriority = parseInt(priority, 10);
      if (!isNaN(numericPriority)) {
        groupedData[rawMonth]['Other'] = numericPriority;
      }
    } else {
      // Accumulate counts for the priority
      groupedData[rawMonth][priority] = ((groupedData[rawMonth][priority] as number) || 0) + (count as number);
    }
  });

  // Convert grouped data into an array of objects
  const result = Object.keys(groupedData).map(rawMonth => ({
    rawMonth, // Keep raw month for sorting
    ...groupedData[rawMonth],
  }));

  // Sort the array chronologically by rawMonth
  result.sort((a, b) => moment(a.rawMonth).toDate().getTime() - moment(b.rawMonth).toDate().getTime());

  // Remove rawMonth from the final output
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return result.map(({ rawMonth, ...rest }) => rest);
};

const transformDataForT12MarketingActivity = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return (
    data
      // Transform data
      .reduce<Array<Record<string, string | number>>>((acc, curr) => {
        const rawMonth = curr['Month of stats_month'] as string;
        const month = moment(rawMonth).format('MMM-YY'); // Format to MMM-DD using moment.js
        const activity = (curr['Measure Names'] as string).replace(/,/g, '').trim(); // Remove commas
        const count = curr['Measure Values'] as number;

        // Check if an entry for the current month already exists
        let existingEntry = acc.find(entry => entry.Month === month);

        if (!existingEntry) {
          // If no entry exists, create a new one
          existingEntry = { Month: month };
          acc.push(existingEntry);
        }

        // Add the count to the corresponding activity key in the existing entry
        existingEntry[activity] = count;

        return acc;
      }, [])
      // Sort by the raw, non-transformed month
      .sort((a, b) => moment(a.Month, 'MMM-YY').toDate().getTime() - moment(b.Month, 'MMM-YY').toDate().getTime())
  );
};

export default transformDataForT12MarketingActivity;

const transformDataForCurrentRentCollection = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return data.reduce<Array<Record<string, string | number>>>((acc, curr) => {
    // Format day_of_month as MMM-DD using moment.js
    const rawDay = curr['day_of_month'] as number;
    const formattedDay = moment().date(rawDay).format('MMM-DD'); // Assume current month for formatting

    const category = (curr['Measure Names'] as string).trim().replaceAll(',', '');

    let value = curr['Collection %'] as number;
    if (category === 'Collection %') {
      value = value * 100;
    }

    // Check if an entry for the current day already exists
    let existingEntry = acc.find(entry => entry['Day of Month'] === formattedDay);

    if (!existingEntry) {
      // If no entry exists, create a new one
      existingEntry = { 'Day of Month': formattedDay };
      acc.push(existingEntry);
    }
    // Add the value to the corresponding category key
    existingEntry[category] = value.toFixed(2);

    return acc;
  }, []);
};

const transformDataForComparitiveRentCollections = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return data.reduce<Array<Record<string, string | number>>>((acc, curr) => {
    // Format Day Of Month as MMM-DD using moment.js
    const rawDay = curr['Day Of Month'] as number;
    const formattedDay = moment().date(rawDay).format('MMM-DD'); // Assume current month for day formatting

    const category = (curr['Measure Names'] as string).trim().replaceAll(',', ''); // Rename Measure Names to Category
    const value = curr['Measure Values'] !== null ? (curr['Measure Values'] as number) * 100 : 0; // Convert to percentage

    // Exclude "Current Collection," with a value of 0
    if (category === 'Current Collection' && value === 0) {
      return acc;
    }

    // Check if an entry for the current day already exists
    let existingEntry = acc.find(entry => entry['Day of Month'] === formattedDay);

    if (!existingEntry) {
      // If no entry exists, create a new one
      existingEntry = { 'Day of Month': formattedDay };
      acc.push(existingEntry);
    }

    // Add the value to the corresponding category key
    existingEntry[`${category} %`] = value.toFixed(2);

    return acc;
  }, []);
};

const transformDataForT12MarketingActivityConversion = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return data.reduce<Array<Record<string, string | number>>>((acc, curr) => {
    // Format 'Month of stats_month' as MMM-YY using moment.js
    const rawMonth = (curr['Month of stats_month'] as string).trim();
    const formattedMonth = moment(rawMonth, 'MMMM YYYY').format('MMM-YY');

    // Rename Measure Names to Category and remove commas
    const category = (curr['Measure Names'] as string).trim().replaceAll(',', '');

    // Convert Measure Values to percentage or set to 0 if null
    const percentage = curr['Measure Values'] !== null ? (curr['Measure Values'] as number) * 100 : 0;

    // Check if an entry for the current month already exists
    let existingEntry = acc.find(entry => entry['Month'] === formattedMonth);

    if (!existingEntry) {
      // If no entry exists, create a new one
      existingEntry = { Month: formattedMonth };
      acc.push(existingEntry);
    }

    // Add the percentage to the corresponding category key
    existingEntry[category] = percentage.toFixed(2);

    return acc;
  }, []);
};

const transformDataForT12TotalMarketingFunnel = (
  data: Array<Record<string, string | number>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  return data.reduce<Array<Record<string, string | number>>>((acc, curr) => {
    const category = (curr['Measure Names'] as string).trim(); // Extract and clean the measure name
    const value = curr['Measure Values'] !== null ? Number(curr['Measure Values']) : 0; // Ensure Measure Values is a number

    // Find an existing entry for the current category
    let existingEntry = acc.find(entry => entry['Measure Names'] === category);

    if (!existingEntry) {
      // If no entry exists, create a new one
      existingEntry = { 'Measure Names': category, 'Measure Values': 0 };
      acc.push(existingEntry);
    }

    // Add the value to the existing category, ensuring Measure Values is a number
    existingEntry['Measure Values'] = Number(existingEntry['Measure Values']) + value;

    return acc;
  }, []);
};

const transformDataForT12WorkOrderCategoryTrends = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  const groupedData: Record<string, Record<string, string | number>> = {};

  data.forEach(entry => {
    const rawMonth = (entry['Month of diwo_created_date'] as string)?.trim().replace(/,$/, '');
    const category = (entry['Category'] as string)?.trim().replace(/,$/, '') || 'Other';
    const count = entry['Count of Custom SQL Query'] as number;

    if (!rawMonth) {
      return; // Skip if the month is missing
    }

    // Use rawMonth for grouping but store formatted month for output
    const formattedMonth = moment(rawMonth).format('MMM-YY');

    // Initialize month group if not already created
    if (!groupedData[rawMonth]) {
      groupedData[rawMonth] = { Month: formattedMonth }; // Save formatted month
    }

    // Accumulate counts for the category
    groupedData[rawMonth][category] = ((groupedData[rawMonth][category] as number) || 0) + count;
  });

  // Convert grouped data into an array of objects
  const result = Object.values(groupedData);

  // Sort the array chronologically by rawMonth
  result.sort((a, b) => {
    const dateA = moment(a.Month as string, 'MMM-YY').toDate();
    const dateB = moment(b.Month as string, 'MMM-YY').toDate();
    return dateA.getTime() - dateB.getTime();
  });

  return result;
};

const transformDataForOccupancyByProperty = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  const groupedData: Record<string, Record<string, string | number>> = {};

  data.forEach(entry => {
    const propertyName = (entry['Property Name'] as string)?.trim().replace(/,$/, '');
    const measureName = (entry['Measure Names'] as string)?.trim().replace(/,$/, '');
    const measureValue = (entry['Measure Values'] as number) ?? 0;
    const occupiedTotal = (entry['Occupied / Total'] as string)?.trim().replace(/,$/, '');

    if (!propertyName || !measureName) {
      return; // Skip invalid entries
    }

    // Initialize property group if not already created
    if (!groupedData[propertyName]) {
      groupedData[propertyName] = { 'Property Name': propertyName, 'Occupied / Total': occupiedTotal };
    }

    // Format values based on the measure name
    let formattedValue: string | number = measureValue;

    if (['$/SqFt', 'Avg Rent', 'Total GPR', 'Total Occupied Rent'].includes(measureName)) {
      if (Number.isInteger(measureValue)) {
        formattedValue = `$${measureValue.toLocaleString()}`; // Format as integer dollar string
      } else {
        formattedValue = `$${measureValue.toFixed(2)}`; // Format as float dollar string
      }
    } else if (['Sq Ft Occupancy', 'Occupancy %', 'Economic Occupancy'].includes(measureName)) {
      formattedValue = `${(measureValue * 100).toFixed(2)}%`; // Format as percentage
    }

    // Add the formatted measure name and value as a key-value pair
    groupedData[propertyName][measureName] = formattedValue;
  });

  // Convert grouped data into an array of objects
  return Object.values(groupedData);
};

const transformDataForCurrentOccupancyTotal = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string>> => {
  if (!data || typeof data === 'string') return [];
  return data
    .filter(entry => {
      // Ensure all required fields are present and valid
      const measureName = entry['Measure Names'];
      const occupiedTotal = entry['Occupied / Total'];
      const measureValue = entry['Measure Values'];

      return typeof measureName === 'string' && typeof occupiedTotal === 'string' && typeof measureValue === 'number';
    })
    .map(entry => {
      const measureName = (entry['Measure Names'] as string)?.trim().replace(/,$/, '');
      const occupiedTotal = (entry['Occupied / Total'] as string)?.trim().replace(/,$/, '');
      const measureValue = entry['Measure Values'] as number;

      let formattedValue: string;

      // Format values based on the measure name
      if (['Occupancy %', 'Economic Occupancy', 'Sq Ft Occupancy'].includes(measureName)) {
        formattedValue = `${(measureValue * 100).toFixed(2)}%`;
      } else {
        // Format as dollar string with commas
        if (Number.isInteger(measureValue)) {
          formattedValue = `$${measureValue.toLocaleString()}`;
        } else {
          formattedValue = `$${measureValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
        }
      }

      // Return transformed entry
      return {
        'Measure Names': measureName,
        'Occupied / Total': occupiedTotal,
        'Measure Values': formattedValue,
      };
    });
};

const transformDataForTop10TradeOutGains = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string | number>> => {
  if (!data || typeof data === 'string') return [];
  const groupedData: Record<string, Record<string, string | number>> = {};

  data.forEach(entry => {
    const propertyName = (entry['Property Name'] as string)?.trim().replace(/,$/, '');
    const unitType = (entry['Unit Type'] as string)?.trim().replace(/,$/, '');
    const measureName = (entry['Measure Names'] as string)?.trim().replace(/,$/, '');
    const measureValue = entry['Measure Values'] as number;
    const monthOfTradeOut = (entry['Month of Trade Out'] as string)?.trim().replace(/,$/, '');
    const count = entry['#'] as number;

    if (!propertyName || !measureName || !monthOfTradeOut) {
      return; // Skip invalid entries
    }

    // Use moment.js to format the month
    const formattedMonth = moment(monthOfTradeOut, 'MMMM YYYY').format('MMM-YYYY');

    // Initialize property group if not already created
    const key = `${propertyName} (${formattedMonth}) - ${unitType}`;
    if (!groupedData[key]) {
      groupedData[key] = {
        'Property Name': propertyName,
        'Unit Type': unitType,
        Month: formattedMonth,
      };
    }

    // Format Measure Values
    let formattedValue: string | number = measureValue;
    if (['AVG $ Gain / Loss', 'AVG Current $', 'AVG Prior $'].includes(measureName)) {
      if (Number.isInteger(measureValue)) {
        formattedValue = `$${measureValue.toLocaleString()}`; // Format integer with commas
      } else {
        formattedValue = `$${measureValue.toFixed(2)}`; // Format float to 2 decimal places
      }
    } else if (measureName === 'AVG % Gain / Loss') {
      formattedValue = `${(measureValue * 100).toFixed(2)}%`; // Format as percentage
    } else if (!Number.isInteger(measureValue)) {
      formattedValue = `$${measureValue.toFixed(2)}`; // General case for non-integer floats
    }

    // Add the measure name and value as a key-value pair
    groupedData[key][measureName] = formattedValue;

    // Add the count if available
    if (!groupedData[key]['#']) {
      groupedData[key]['#'] = count || 0;
    }
  });

  // Ensure order of first three keys and prepare final array
  const orderedArray = Object.values(groupedData).map(item => {
    const reorderedItem: Record<string, string | number> = {
      'Property Name': item['Property Name'],
      'Unit Type': item['Unit Type'],
      Month: item['Month'],
    };

    // Add remaining keys in their original order
    Object.keys(item).forEach(key => {
      if (!reorderedItem[key]) {
        reorderedItem[key] = item[key];
      }
    });

    return reorderedItem;
  });

  // Sort the array by the absolute value of AVG $ Gain / Loss in descending order
  orderedArray.sort((a, b) => {
    const getAbsoluteValue = (item: Record<string, string | number>, key: string): number => {
      const value = item[key];
      if (typeof value === 'string' && value.startsWith('$')) {
        return Math.abs(parseFloat(value.replace(/[$,]/g, '')));
      }
      return 0;
    };

    return getAbsoluteValue(b, 'AVG $ Gain / Loss') - getAbsoluteValue(a, 'AVG $ Gain / Loss');
  });

  return orderedArray;
};

const transformDataForT6RenewalRatesByProperty = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string>> => {
  if (!data || typeof data === 'string') return [];
  const groupedData: Record<string, Record<string, string>> = {};

  data.forEach(entry => {
    const propertyName = (entry['Property Name'] as string)?.trim().replace(/,$/, '');
    const month = (entry['Month of stats_month'] as string)?.trim().replace(/,$/, '');
    const renewalRate = entry['Renewal Rate'] as number | null;

    if (!propertyName || !month) {
      return; // Skip invalid entries
    }

    // Use moment.js to format the month
    const formattedMonth = moment(month, 'MMMM YYYY').format('MMM-YY');

    // Initialize property group if not already created
    if (!groupedData[propertyName]) {
      groupedData[propertyName] = { 'Property Name': propertyName };
    }

    // Format the renewal rate as a percentage
    const formattedRenewalRate = renewalRate !== null ? `${(renewalRate * 100).toFixed(2)}%` : 'N/A';

    // Add the month and its renewal rate
    groupedData[propertyName][formattedMonth] = formattedRenewalRate;
  });

  // Convert grouped data into an array of objects and enforce a maximum of 6 months
  return Object.values(groupedData).map(propertyData => {
    // Extract and sort the month keys chronologically
    const monthKeys = Object.keys(propertyData)
      .filter(key => key !== 'Property Name')
      .sort((a, b) => moment(a, 'MMM-YY').diff(moment(b, 'MMM-YY')));

    // Retain only the first 6 months
    const allowedMonths = monthKeys.slice(0, 6);

    // Create a new object with only the allowed months
    const trimmedData: Record<string, string> = {
      'Property Name': propertyData['Property Name'],
    };
    allowedMonths.forEach(month => {
      trimmedData[month] = propertyData[month];
    });

    return trimmedData;
  });
};

export const transformDataForAgingSummary = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string | Record<string, string>>> => {
  if (!data || typeof data === 'string') return [];
  const groupedData: Record<string, Record<string, string>> = {};

  data.forEach(entry => {
    const agingDays = (entry['Aging Days'] as string)?.trim();
    const propertyName = (entry['Property Name'] as string)?.trim();
    let balance = entry['balance'] as number | null;

    if (!agingDays || !propertyName) {
      return; // Skip invalid entries
    }

    // Set negative balances to 0
    if (balance !== null && balance < 0) {
      balance = 0;
    }

    // Initialize aging group if not already created
    if (!groupedData[agingDays]) {
      groupedData[agingDays] = { 'Aging Days': agingDays };
    }

    // Format the balance as a dollar string
    const formattedBalance =
      balance !== null ? `$${balance.toLocaleString('en-US', { minimumFractionDigits: 2 })}` : 'N/A';

    // Add the property and its balance
    groupedData[agingDays][propertyName] = formattedBalance;
  });

  // Convert grouped data into an array of objects
  const transformedArray = Object.values(groupedData);

  // Custom sort order for aging days
  const sortOrder = ['0-30', '30-60', '60-90', '90+'];
  transformedArray.sort(
    (a, b) => sortOrder.indexOf(a['Aging Days'] as string) - sortOrder.indexOf(b['Aging Days'] as string)
  );

  return transformedArray;
};

export const transformDataForTenantsWithABalance = (
  data: Array<Record<string, string | number | null>>
): Array<Record<string, string | Record<string, string>>> => {
  if (!data || typeof data === 'string') return [];
  const groupedData: Record<string, Record<string, string>> = {};
  data.forEach(entry => {
    const agingDays = (entry['Aging Days'] as string)?.trim();
    const propertyName = (entry['Property Name'] as string)?.trim();
    const numTenants = entry['num_tenants'] as number | null;

    if (!agingDays || !propertyName) {
      return; // Skip invalid entries
    }

    // Initialize aging group if not already created
    if (!groupedData[agingDays]) {
      groupedData[agingDays] = { 'Aging Days': agingDays };
    }

    // Rename and add the tenant count
    const tenantCount = numTenants !== null ? numTenants.toString() : 'N/A';
    groupedData[agingDays][propertyName] = tenantCount;
  });

  // Convert grouped data into an array of objects
  const transformedArray = Object.values(groupedData);

  // Custom sort order for aging days
  const sortOrder = ['0-30', '30-60', '60-90', '90+'];
  transformedArray.sort(
    (a, b) => sortOrder.indexOf(a['Aging Days'] as string) - sortOrder.indexOf(b['Aging Days'] as string)
  );

  return transformedArray;
};

export function transformDataForCurrentTenantUnitStatus(
  data: Array<Record<string, any>>
): Record<string, Array<Record<string, any>>> {
  const transformed: Record<string, Array<Record<string, any>>> = {
    Occupied: [],
    Vacant: [],
  };
  if (!data || typeof data === 'string') return transformed;
  data.forEach(property => {
    const propertyName = property['Property Name']?.replace(/,$/, ''); // Remove trailing comma
    const occupiedStatuses: Record<string, number> = {};
    const vacantStatuses: Record<string, number> = {};

    property.statuses.forEach((status: Record<string, any>) => {
      const category = status['Occupied / Vacant']?.replace(/,$/, ''); // Remove trailing comma
      const statusName = status['status']?.replace(/,$/, ''); // Remove trailing comma
      const unitCount = status['Unit Count'];

      if (category === 'Occupied') {
        occupiedStatuses[statusName] = unitCount;
      } else if (category === 'Vacant') {
        vacantStatuses[statusName] = unitCount;
      }
    });

    if (Object.keys(occupiedStatuses).length > 0) {
      transformed.Occupied.push({
        'Property Name': propertyName,
        statuses: occupiedStatuses,
      });
    }

    if (Object.keys(vacantStatuses).length > 0) {
      transformed.Vacant.push({
        'Property Name': propertyName,
        statuses: vacantStatuses,
      });
    }
  });

  return transformed;
}

export const transformReportBuilderDataForVisualization = (
  id: string,
  data: Array<Record<string, string | number>>
):
  | ReturnType<typeof transformDataForInPlaceRent>
  | ReturnType<typeof transformDataForT12MarketingActivity>
  | ReturnType<typeof transformDataForT12WorkOrderPriorityTrends>
  | ReturnType<typeof transformDataForCurrentRentCollection>
  | ReturnType<typeof transformDataForComparitiveRentCollections>
  | ReturnType<typeof transformDataForT12MarketingActivityConversion>
  | ReturnType<typeof transformDataForT12TotalMarketingFunnel>
  | ReturnType<typeof transformDataForT12WorkOrderCategoryTrends>
  | ReturnType<typeof transformDataForOccupancyByProperty>
  | ReturnType<typeof transformDataForCurrentOccupancyTotal>
  | ReturnType<typeof transformDataForTop10TradeOutGains>
  | ReturnType<typeof transformDataForT6RenewalRatesByProperty> => {
  switch (id) {
    case ReportBuilderKPIMap.NEXT_10W_MOVE_OUTS as string:
      return transformDataForNext10WKPIs(data);
    case ReportBuilderKPIMap.NEXT_10W_MOVE_INS as string:
      return transformDataForNext10WKPIs(data);
    case ReportBuilderKPIMap.NEXT_10W_CUMULATIVE_EXPOSURE as string:
      return transformDataForNext10WKPIs(data);
    case ReportBuilderKPIMap.NEXT_10W_LEASE_EXPIRATIONS as string:
      return transformDataForNext10WKPIs(data);
    case ReportBuilderKPIMap.T12_LEASE_TRADE_OUTS_BY_MONTH as string:
      return transformDataForT12LeaseTradeOutsByMonth(data);
    case ReportBuilderKPIMap.T6_NUM_OF_MOVE_OUTS as string:
      return transformDataForT6ResidentActivity(data);
    case ReportBuilderKPIMap.T6_NUM_OF_MOVE_INS as string:
      return transformDataForT6ResidentActivity(data);
    case ReportBuilderKPIMap.T6_NUM_OF_LEASE_RENEWALS as string:
      return transformDataForT6ResidentActivity(data);
    case ReportBuilderKPIMap.T6_NUM_OF_LEASES_SIGNED as string:
      return transformDataForT6ResidentActivity(data);
    case ReportBuilderKPIMap.T6_MONTHLY_LEASE_RENEWAL_RATE as string:
      return transformDataForT6MonthlyLeaseRenewalRates(data);
    case ReportBuilderKPIMap.T12_AVG_$_INCREMENTAL_LEASE_VALUE as string:
      return transformDataForT12AvgIncrementalLeaseValue(data);
    case ReportBuilderKPIMap.T6_AVERAGE_GAIN_LOSS_ON_TRADE_OUT as string:
      return transformDataForT6AverageGainLossOnTradeOut(data);
    case ReportBuilderKPIMap.T6_DAILY_OPEN_WORK_ORDERS as string:
      return transformDataForT6DailyOpenWorkOrders(data);
    case ReportBuilderKPIMap.T12_MONTHLY_CREATED_WORK_ORDERS as string:
      return transformDataForT12MonthlyCreatedWorkOrders(data);
    case ReportBuilderKPIMap.T12_MONTHLY_COMPLETED_WORK_ORDERS as string:
      return transformDataForT12MonthlyCompletedWorkOrders(data);
    case ReportBuilderKPIMap.T6_DAILY_OCCUPANCY_RATE as string:
      return transformDataForT6DailyOccupancyRate(data);
    case ReportBuilderKPIMap.T6_DAILY_ECONOMIC_OCCUPANCY as string:
      return transformDataForT6DailyEconomicOccupancy(data);
    case ReportBuilderKPIMap.T6_DAILY_SQFT_OCCUPANCY as string:
      return transformDataForT6DailyEconomicOccupancy(data);
    case ReportBuilderKPIMap.T12_AVG_DAYS_TO_COMPLETE_WORK_ORDER as string:
      return transformDataForT12AvgDaysToCompleteWorkOrder(data);
    case ReportBuilderKPIMap.IN_PLACE_RENT as string:
      return transformDataForInPlaceRent(data);
    case ReportBuilderKPIMap.T12_MARKETING_ACTIVITY as string:
      return transformDataForT12MarketingActivity(data);
    case ReportBuilderKPIMap.T12_WORK_ORDER_PRIORITY_TRENDS as string:
      return transformDataForT12WorkOrderPriorityTrends(data);
    case ReportBuilderKPIMap.CURRENT_RENT_COLLECTION as string:
      return transformDataForCurrentRentCollection(data);
    case ReportBuilderKPIMap.COMPARITIVE_RENT_COLLECTIONS as string:
      return transformDataForComparitiveRentCollections(data);
    case ReportBuilderKPIMap.T12_MARKETING_ACTIVITY_CONVERSION as string:
      return transformDataForT12MarketingActivityConversion(data);
    case ReportBuilderKPIMap.T12_TOTAL_MARKETING_FUNNEL as string:
      return transformDataForT12TotalMarketingFunnel(data);
    case ReportBuilderKPIMap.T12_WORK_ORDER_CATEGORY_TRENDS as string:
      return transformDataForT12WorkOrderCategoryTrends(data);
    case ReportBuilderKPIMap.OCCUPANCY_BY_PROPERTY as string:
      return transformDataForOccupancyByProperty(data);
    case ReportBuilderKPIMap.CURRENT_OCCUPANCY_TOTAL as string:
      return transformDataForCurrentOccupancyTotal(data);
    case ReportBuilderKPIMap.TOP_10_TRADE_OUT_GAINS as string:
      return transformDataForTop10TradeOutGains(data);
    case ReportBuilderKPIMap.BOTTOM_10_TRADE_OUT_GAINS as string:
      return transformDataForTop10TradeOutGains(data);
    case ReportBuilderKPIMap.T6_LEASE_RENEWAL_RATES_BY_PROPERTY as string:
      return transformDataForT6RenewalRatesByProperty(data);
    default:
      return data;
  }
};
