import { ReportBuilderKPIMap } from 'data/reportBuilderData';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList, Cell } from 'recharts';

interface ChartData {
  [key: string]: string | number;
}

interface ReportBuilderBarChartProps {
  id: string;
  data: ChartData[];
}

const getBarColor = (id: string, value: number) => {
  if (id === ReportBuilderKPIMap.T6_AVERAGE_GAIN_LOSS_ON_TRADE_OUT) {
    return value >= 0 ? '#264653' : '#AA0201';
  }
  switch (id) {
    case ReportBuilderKPIMap.NEXT_10W_CUMULATIVE_EXPOSURE:
      return '#EBD26E';
    case ReportBuilderKPIMap.NEXT_10W_LEASE_EXPIRATIONS:
      return '#92BEDB';
    case ReportBuilderKPIMap.NEXT_10W_MOVE_INS:
      return '#BAB0AC';
    case ReportBuilderKPIMap.NEXT_10W_MOVE_OUTS:
      return '#E15759';
    case ReportBuilderKPIMap.T6_NUM_OF_LEASE_RENEWALS:
      return '#E15759';
    case ReportBuilderKPIMap.T12_LEASE_TRADE_OUTS_BY_MONTH:
      return '#ECD36C';
    case ReportBuilderKPIMap.T6_NUM_OF_MOVE_OUTS:
      return '#F28E2B';
    case ReportBuilderKPIMap.T6_NUM_OF_MOVE_INS:
      return '#4E79A7';
    case ReportBuilderKPIMap.T6_NUM_OF_LEASES_SIGNED:
      return '#EDC947';
    case ReportBuilderKPIMap.T6_MONTHLY_LEASE_RENEWAL_RATE:
      return '#ECD36C';
    case ReportBuilderKPIMap.T12_AVG_$_INCREMENTAL_LEASE_VALUE:
      return '#92BEDB';
    default:
      return '#000'; // Default color
  }
};

const formatPercentageLabel = (value: number) => {
  return `${value}%`;
};

const formatDollarLabel = (value: number) => {
  return `$${value}`;
};

const ReportBuilderBarChart: React.FC<ReportBuilderBarChartProps> = ({ id, data }) => {
  // Empty data should be handled in outer component ReportBuilderResizableItem for debugging purposes
  // Guard clause should be if Tableau returned an error (status code 429, etc)
  if (!Array.isArray(data) || data.length === 0) {
    return;
  }
  const dataKey = Object.keys(data[0])[1];
  const labelKey = Object.keys(data[0])[0];

  // Determine the min and max values for the Y-axis domain
  const values = data.map(item => item[dataKey] as number);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  const yAxisDomain =
    id === ReportBuilderKPIMap.T6_AVERAGE_GAIN_LOSS_ON_TRADE_OUT
      ? [Math.min(0, minValue) - 2, Math.max(0, maxValue)]
      : id === ReportBuilderKPIMap.T12_AVG_$_INCREMENTAL_LEASE_VALUE
        ? [Math.min(0, minValue) - 50, Math.max(0, maxValue) + 50]
        : undefined;

  return (
    <ResponsiveContainer width="100%" height="80%">
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }} barGap={7}>
        <XAxis dataKey={labelKey} />
        <YAxis hide domain={yAxisDomain} />
        <Tooltip
          formatter={
            id === ReportBuilderKPIMap.T6_MONTHLY_LEASE_RENEWAL_RATE ||
            id === ReportBuilderKPIMap.T6_AVERAGE_GAIN_LOSS_ON_TRADE_OUT
              ? formatPercentageLabel
              : id === ReportBuilderKPIMap.T12_AVG_$_INCREMENTAL_LEASE_VALUE
                ? formatDollarLabel
                : undefined
          }
        />
        <Bar dataKey={dataKey}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getBarColor(id, entry[dataKey] as number)} />
          ))}
          <LabelList
            dataKey={dataKey}
            position={
              id === ReportBuilderKPIMap.NEXT_10W_CUMULATIVE_EXPOSURE ||
              id === ReportBuilderKPIMap.T6_AVERAGE_GAIN_LOSS_ON_TRADE_OUT
                ? 'bottom'
                : 'top'
            }
            formatter={
              id === ReportBuilderKPIMap.T6_MONTHLY_LEASE_RENEWAL_RATE ||
              id === ReportBuilderKPIMap.T6_AVERAGE_GAIN_LOSS_ON_TRADE_OUT
                ? formatPercentageLabel
                : id === ReportBuilderKPIMap.T12_AVG_$_INCREMENTAL_LEASE_VALUE
                  ? formatDollarLabel
                  : undefined
            }
            fill="#000"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ReportBuilderBarChart;
