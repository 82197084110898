import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

// Define a color palette for the bars
const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffbb28'];

// Props type for StackedBarChart
interface ReportBuilderStackedBarChartProps {
  id: string;
  data: Array<Record<string, string | number>>;
}

// Helper function to calculate totals and add them to the data
const calculateTotals = (data: Array<Record<string, string | number>>, barKeys: string[]) => {
  return data.map(entry => {
    const total = barKeys.reduce((sum, key) => sum + ((entry[key] as number) || 0), 0);
    return { ...entry, total };
  });
};

// StackedBarChart Component
const ReportBuilderStackedBarChart: React.FC<ReportBuilderStackedBarChartProps> = ({ data }) => {
  // Empty data should be handled in outer component ReportBuilderResizableItem for debugging purposes
  // Guard clause should be if Tableau returned an error (status code 429, etc)
  if (!Array.isArray(data) || data.length === 0) {
    return;
  }

  // The x-axis key is assumed to be the first key in the data
  const xKey = Object.keys(data[0])[0];
  // Bar keys are all other keys excluding the x-axis key
  const barKeys = Object.keys(data[0]).slice(1);

  // Calculate totals for each bar
  const dataWithTotals = calculateTotals(data, barKeys);

  return (
    <ResponsiveContainer width="100%" height="90%">
      <BarChart data={dataWithTotals} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey={xKey} />
        <YAxis hide />
        <Tooltip />
        <Legend />

        {/* Render stacked bars dynamically with unique colors */}
        {barKeys.map((key, index) => {
          const isLastStack = index === barKeys.length - 1;
          return (
            <Bar key={key} dataKey={key} fill={COLORS[index % COLORS.length]} stackId="stack">
              {/* Add LabelList for the total on the last stack */}
              {isLastStack && (
                <LabelList
                  dataKey="total" // Use the `total` field to display the sum
                  position="top" // Place above the bar
                  fill="#000"
                  formatter={(value: number) => `${value}`} // Format the label
                />
              )}
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ReportBuilderStackedBarChart;
