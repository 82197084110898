import { useRouter } from 'next/router';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { LoadingSpinner } from '@/components/ui/spinner';
import { zodResolver } from '@hookform/resolvers/zod';
import { Mail } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserApi } from 'services/api';
import { z } from 'zod';
import Image from 'next/image';
import Link from 'next/link';
import { User } from 'src/types/User/user';
interface ReportBuilderEmailFormProps {
  emailSubmitted: boolean;
  setEmailSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReportBuilderEmailForm: React.FC<ReportBuilderEmailFormProps> = ({ setEmailSubmitted }) => {
  const router = useRouter();
  const { reportId } = router.query;
  const [user, setUser] = useState<User | null>();
  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {
    if (!reportId) return;

    const getReportUser = async () => {
      setLoadingUser(true);
      try {
        const { data } = await UserApi.getReport(reportId);
        if (!data.user) return;
        setUser(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingUser(false);
      }
    };

    getReportUser();
  }, [reportId]);

  const [loadingEmailSubmission, setLoadingEmailSubmission] = useState(false);
  const emailSubmissionFormSchema = z.object({
    email: z.string().email({
      message: 'Please enter a valid email address',
    }),
  });

  const emailSubmissionForm = useForm<z.infer<typeof emailSubmissionFormSchema>>({
    resolver: zodResolver(emailSubmissionFormSchema),
    defaultValues: {
      email: '',
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    setError,
  } = emailSubmissionForm;

  const onSubmit = async (values: z.infer<typeof emailSubmissionFormSchema>) => {
    setLoadingEmailSubmission(true);

    try {
      const { email } = values;
      await UserApi.saveEmailLead({ email, reportId });
      setEmailSubmitted(true);
    } catch (error) {
      setError('email', {
        message: 'Please provide a valid email address',
      });
    } finally {
      setLoadingEmailSubmission(false);
    }
  };

  if (loadingUser) {
    return (
      <div className="flex items-center justify-center h-full">
        <LoadingSpinner className="text-black" height={100} width={100} />
      </div>
    );
  }

  return (
    <div
      className="relative h-full grid place-items-center bg-cover bg-center"
      style={{ backgroundImage: "url('/static/images/report_builder_background.png')" }}>
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black opacity-70"></div>

      {/* Form Container */}
      <div className="relative bg-white-50 flex flex-col gap-1 items-center pt-5 mx-auto max-w-[650px] rounded-lg">
        <Image src="/static/images/leni-logo-black.svg" height={125} width={125} alt="Leni logo" />
        <p className="text-md text-gray-600 text-center max-w-[87.5%] mb-5">
          {user?.firstName} {user?.lastName} built an interesting report and shared it with you on Leni! Please enter
          your email to view the report.
        </p>
        <Form {...emailSubmissionForm}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 w-[80%]">
            <FormField
              control={emailSubmissionForm.control}
              name="email"
              render={({ field }) => (
                <FormItem className="text-left">
                  <FormLabel className="text-sm font-bold text-gray-500">Your Business Email</FormLabel>
                  <FormControl
                    className={`focus:outline-none active:outline-none ${errors?.email && 'border-error-500'}`}>
                    <Input
                      icon={<Mail className="h-[16px] w-[16px] text-gray-400" />}
                      placeholder="john.doe@gmail.com"
                      {...field}
                      error={errors?.email}
                      aria-invalid={errors?.email ? 'true' : 'false'}
                      className="focus:outline-none active:outline-none"
                      type="email"
                    />
                  </FormControl>
                  {errors?.email && (
                    <FormDescription className="text-error-500 text-xs">*{errors?.email.message}</FormDescription>
                  )}
                </FormItem>
              )}
            />
            <Button className="text-black bg-accent-400 cursor-pointer hover:bg-accent-200 mt-5 w-full" size="sm">
              {!loadingEmailSubmission ? (
                'Continue'
              ) : (
                <div className="flex items-center justify-center">
                  <LoadingSpinner className="mr-3" height={12} width={12} />
                  <span className="flex items-center text-center">Submitting Email...</span>
                </div>
              )}
            </Button>
          </form>
        </Form>
        <p className="text-[10px] pb-5 text-center max-w-[85%] mb-5 mt-5">
          Our offering is governed by the{' '}
          <Link href="https://leni.co/wpautoterms/terms-and-conditions/">Terms of Use</Link>, and we may use your
          personal data for marketing and account verification as outlined in our{' '}
          <Link href="https://leni.co/wpautoterms/professional-privacy-policy/">Privacy Policy</Link>. By providing your
          email you acknowledge that Leni may contact you with information regarding their product offerings.
        </p>
      </div>
    </div>
  );
};

export default ReportBuilderEmailForm;
