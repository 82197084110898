import ReportBuilderShareContainer from 'containers/ReportBuilder/ReportBuilderShare';

const ReportDownloadLayout: React.FC = () => {
  return (
    <main className="min-h-full">
      <ReportBuilderShareContainer downloadPage />
    </main>
  );
};

export default ReportDownloadLayout;
