import React from 'react';
import PropTypes from 'prop-types';
import { useSWRConfig } from 'swr';
import AuthLayout from 'components/AuthLayout';
import GenericLayout from 'components/GenericLayout';
import OnboardingLayout from 'components/OnboardingLayout';
import MaintenanceLayout from 'components/MaintenanceLayout';
import Maintenance from 'components/Maintenance';
import ServicesStatus from 'components/ServicesStatus';
import ResidentsPropertyPageLayout from 'components/ResidentsPropertyLayout';
import StayOnPageLayout from 'components/StayOnPageLayout';
import ReportPageLayout from 'components/ReportPageLayout';
import { AuthApi } from 'services/api';
import { Router } from 'utils/with-i18next';
import { useUser } from 'contexts/UserContext.tsx';
import { AuthorizedLayout } from './AuthorizedLayout';
import { createErrorStatusCodeHandler } from 'utils/default-http-error-handler';
import { useErrorTracker } from 'utils/use-error-tracker';
import useTableauDashboardContext from 'utils/hooks/useTableauDashboardContext';
import useReportBuilderContext from 'utils/hooks/useReportBuilderContext';
import ReportDownloadLayout from 'components/ReportDownloadLayout';

export const Layouts = {
  AUTH: 'auth',
  ONBOARDING: 'onboarding',
  DASHBOARD: 'dashboard',
  GENERIC: 'generic',
  STAY_ON_PAGE: 'stayOnPage',
  RESIDENTS: 'residents',
  MAINTENANCE: 'maintenance',
  STATUS_PAGE: 'serviceStatus',
  REPORTS: 'reports',
  DOWNLOAD_REPORT: 'downloadReport',
};

export function Layout({ children, type, pageId, image, byPassAuth = false }) {
  const errorTracker = useErrorTracker();
  const { tableauDashboardDispatch } = useTableauDashboardContext();
  const { dispatch: reportBuilderDispatch } = useReportBuilderContext();
  /**
   * check router history
   * if(router history > 1)
   */
  const { cache } = useSWRConfig();
  const redirectTo = Layouts.AUTH === type ? '/dashboard/bookings' : '/login';
  const redirectIfFound = Layouts.AUTH === type ? true : false;

  const { user, clearUser } = useUser({
    ...(!byPassAuth && { redirectTo, redirectIfFound }),
  });

  const handleLogout = () => {
    AuthApi.logout()
      .then(() => {
        tableauDashboardDispatch({
          type: 'resetState',
        });
        reportBuilderDispatch({
          type: 'RESET',
        });
        cache.clear();
        clearUser();
        Router.push('/login');
      })
      .catch(createErrorStatusCodeHandler('logout', null, errorTracker));
  };

  if (process.env.NEXT_PUBLIC_MAINTENANCE === 'true') {
    return (
      <MaintenanceLayout>
        <Maintenance />
      </MaintenanceLayout>
    );
  }

  switch (type) {
    case Layouts.AUTH:
      return <AuthLayout>{children}</AuthLayout>;
    case Layouts.MAINTENANCE:
      return (
        <MaintenanceLayout>
          <Maintenance />
        </MaintenanceLayout>
      );
    case Layouts.STATUS_PAGE:
      return (
        <MaintenanceLayout>
          <ServicesStatus />
        </MaintenanceLayout>
      );
    case Layouts.DASHBOARD:
      return (
        <AuthorizedLayout user={user} pageId={pageId} handleLogout={handleLogout}>
          {children}
        </AuthorizedLayout>
      );
    case Layouts.ONBOARDING:
      return (
        <OnboardingLayout image={image} currentUser={user} handleLogout={handleLogout}>
          {children}
        </OnboardingLayout>
      );
    case Layouts.RESIDENTS:
      return (
        <ResidentsPropertyPageLayout image={image} currentUser={user} handleLogout={handleLogout}>
          {children}
        </ResidentsPropertyPageLayout>
      );
    case Layouts.STAY_ON_PAGE:
      return (
        <StayOnPageLayout image={image} currentUser={user} handleLogout={handleLogout}>
          {children}
        </StayOnPageLayout>
      );
    case Layouts.REPORTS:
      return <ReportPageLayout>{children}</ReportPageLayout>;
    case Layouts.DOWNLOAD_REPORT:
      return <ReportDownloadLayout>{children}</ReportDownloadLayout>;
    default:
      return (
        <GenericLayout handleLogout={handleLogout} currentUser={user}>
          {children}
        </GenericLayout>
      );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.values(Layouts)),
  pageId: PropTypes.string,
  image: PropTypes.string,
};

export default Layout;
