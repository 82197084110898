import React from 'react';
import { FunnelChart, Funnel, Tooltip, ResponsiveContainer } from 'recharts';

interface ReportBuilderFunnelChartProps {
  data: Array<Record<string, string | number>>;
  id: string;
}

// color palette - can add more if needed
const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'];

// Custom rectangular shape for funnel segments with gaps and labels on top
const renderRectShape = (props: any) => {
  const { x, y, width, height, value, name, payload } = props;

  const index = payload.index;

  const fillColor = COLORS[index];

  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill={fillColor} stroke="#fff" />

      <text
        x={x + width / 2}
        y={y + height / 2}
        textAnchor="middle"
        dominantBaseline="middle"
        fill="black"
        fontSize={12}
        fontWeight="bold">
        {name}: {value.toLocaleString()}
      </text>
    </g>
  );
};

// FunnelChart Component
const ReportBuilderFunnelChart: React.FC<ReportBuilderFunnelChartProps> = ({ data }) => {
  // Empty data should be handled in outer component ReportBuilderResizableItem for debugging purposes
  // Guard clause should be if Tableau returned an error (status code 429, etc)
  if (!Array.isArray(data) || data.length === 0) {
    return;
  }

  // Reformat data for recharts FunnelChart
  const funnelData = data.map((item, index) => ({
    name: item['Measure Names'],
    value: item['Measure Values'],
    index, // Add index to the data for easier reference
  }));

  return (
    <ResponsiveContainer width="100%" height="90%">
      <FunnelChart>
        <Tooltip formatter={(value: number) => `${value.toLocaleString()} items`} />
        <Funnel
          dataKey="value"
          data={funnelData}
          isAnimationActive
          shape={(props: any) => renderRectShape(props)} // Use the updated renderRectShape
        />
      </FunnelChart>
    </ResponsiveContainer>
  );
};

export default ReportBuilderFunnelChart;
