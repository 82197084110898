import React from 'react';

interface LeniQSuggestionQuestionProps {
  question: string;
  onClick: (q: string) => void;
}

const LeniQSuggestionQuestion: React.FC<LeniQSuggestionQuestionProps> = ({ question, onClick }) => {
  return (
    <div
      className={`w-fit p-2 flex items-center my-1 mr-1 gap-2 rounded-full border-[1px] border-primary-200 cursor-pointer hover:shadow-md transition-all`}
      onClick={() => onClick(question)}>
      <span className="text-primary-700 px-2">{question}</span>
    </div>
  );
};

export default LeniQSuggestionQuestion;
