import { ScrollArea } from '@/components/ui/scroll-area';
import React from 'react';
import { motion } from 'framer-motion';
import useInsightContext from 'utils/hooks/useInsightContext';

const InsightMonitoringBody: React.FC = () => {
  // Note that this component is currently only rendered for dev.prod account, as the monitoringInsightsData
  // is hardcoded as a demo. See src/containers/Insights/index.tsx
  const {
    state: { allMonitoringInsights },
  } = useInsightContext();

  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ duration: 0.3, ease: 'linear' }}
      className="h-full overflow-hidden">
      <ScrollArea className="h-full p-5">
        <div className="flex flex-col gap-3">
          {allMonitoringInsights.map(insight => {
            return (
              <div key={insight.id}>
                <h4 className="text-primary-900 text-sm mb-2">Monitoring Update</h4>
                <div className="flex flex-col border-[1px] border-b-[0px] border-gray-200 rounded-md">
                  <div className="px-3 py-2 border-b-[1px] border-gray-200">
                    <div className="flex items-center justify-between text-gray-400 text-xs mb-1">
                      <span>{insight.date}</span>
                      <span>{insight.time}</span>
                    </div>
                    {insight.update}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ScrollArea>
    </motion.div>
  );
};

export default InsightMonitoringBody;
