import { useContext } from 'react';
import { DashboardContext, DashboardContextType } from 'contexts/DashboardContext';

const useDashboardContext = (): DashboardContextType => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardContextProvider');
  }
  return context;
};

export default useDashboardContext;
