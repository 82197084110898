import React, { useState, useEffect } from 'react';

const messages = [
  'Analyzing your question…',
  'Retrieving conversation context…',
  'Identifying key details in your query…',
  'Searching the database…',
  'Looking up relevant information…',
  'Formulating a detailed query…',
  'Assembling the information…',
  'Preparing the response structure…',
  'Synthesizing the response…',
];

const LeniQProgressMessages: React.FC = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const getRandomInterval = () => Math.floor(Math.random() * 3000) + 1000;

    const updateMessage = () => {
      setCurrentMessageIndex(prev => {
        if (prev + 1 < messages.length) {
          console.log('currentMessageIndex', prev);
          console.log('messages.length', messages.length);
          const nextInterval = getRandomInterval();
          timeoutRef.current = setTimeout(updateMessage, nextInterval);
        }
        return prev + 1 < messages.length ? prev + 1 : prev;
      });
    };

    const timeoutRef = { current: setTimeout(updateMessage, getRandomInterval()) };

    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div
      className="inline-block text-center text-[rgba(255,255,255,0.4)] animate-shimmer bg-clip-text text-transparent opacity-50"
      style={{
        backgroundImage: 'linear-gradient(to right, transparent, transparent, #fff 50%, transparent, transparent)',
        backgroundSize: '300% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#222',
      }}>
      {messages[currentMessageIndex]}
    </div>
  );
};

export default LeniQProgressMessages;
