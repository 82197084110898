import React from 'react';
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface ReportBuilderCurrentTenantUnitStatusProps {
  id: string;
  data: Record<string, Array<Record<string, any>>>;
}

// Input data transformation
const transformData = (data: Array<Record<string, any>>) => {
  // Dynamically determine the property key (e.g., "Property Name")
  const propertyKey = Object.keys(data[0]).find(key => typeof data[0][key] === 'string') || 'Property Name';

  // Transform data
  const transformedData = data.map(item => {
    const newItem: Record<string, number | string> = { [propertyKey]: item[propertyKey] };
    let total = 0;

    Object.entries(item.statuses).forEach(([status, value]) => {
      const numericValue = Number(value) || 0;
      newItem[status] = numericValue;
      total += numericValue;
    });

    newItem.total = total; // Add total for each property
    return newItem;
  });

  // Get unique statuses and sort for each property
  const statusKeys = Array.from(
    new Set(transformedData.flatMap(item => Object.keys(item).filter(key => key !== propertyKey && key !== 'total')))
  );

  return { transformedData, statusKeys, propertyKey };
};

const sortStatusKeysForBars = (statusKeys: string[], data: Array<Record<string, any>>) => {
  return statusKeys.sort((a, b) => {
    const totalA = data.reduce((sum, item) => sum + (item[a] || 0), 0);
    const totalB = data.reduce((sum, item) => sum + (item[b] || 0), 0);
    return totalB - totalA; // Largest to smallest
  });
};

const ReportBuilderCurrentTenantUnitStatus: React.FC<ReportBuilderCurrentTenantUnitStatusProps> = ({ data }) => {
  // Data is an object not an array {Occupied: [], Vacant: []}
  // Empty data should be handled in outer component ReportBuilderResizableItem for debugging purposes
  // Guard clause should be if Tableau returned an error (status code 429, etc)
  if (typeof data === 'object' && !Array.isArray(data)) {
    if (Object.keys(data).length === 0) {
      return;
    }

    if (Object.values(data).every(arr => Array.isArray(arr) && arr.length === 0)) {
      return (
        <div className="flex items-start justify-center h-full">
          <p className="text-gray-500">No data available</p>
        </div>
      );
    }
  }
  const colNames = Object.keys(data);

  const {
    transformedData: vacantData,
    statusKeys: vacantStatusKeys,
    propertyKey: vacantPropertyKey,
  } = transformData(data['Vacant']);
  const {
    transformedData: occupiedData,
    statusKeys: occupiedStatusKeys,
    propertyKey: occupiedPropertyKey,
  } = transformData(data['Occupied']);

  // Sort stacked bars for largest-to-smallest segments
  const sortedVacantKeys = sortStatusKeysForBars(vacantStatusKeys, vacantData);
  const sortedOccupiedKeys = sortStatusKeysForBars(occupiedStatusKeys, occupiedData);

  return (
    <div className="w-full h-[90%] grid grid-cols-[1fr,1fr] grid-rows-[30px_auto]">
      {colNames.map(name => (
        <span key={name} className="col-span-1 flex justify-center first:border-r-[1px] border-b-[1px] border-gray-400">
          {name}
        </span>
      ))}
      <div className="col-span-1 border-r-[1px] border-gray-400">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={occupiedData} layout="vertical" margin={{ top: 20, right: 50, left: 10, bottom: 20 }}>
            <XAxis type="number" hide />
            <YAxis dataKey={occupiedPropertyKey} type="category" width={150} />
            <Tooltip />
            <Legend />
            {sortedOccupiedKeys.map((status, index) => {
              // Check if this is the last stacked item
              const isLastStack = index === sortedOccupiedKeys.length - 1;

              return (
                <Bar
                  key={status}
                  dataKey={status}
                  stackId="a"
                  fill={status === 'Notice' ? '#F0B02F' : status === 'Current' ? '#62D926' : '#D82626'}>
                  {isLastStack && (
                    <LabelList
                      dataKey="total" // Use the `total` field to display the sum
                      position="right"
                      fill="#000"
                      formatter={(value: number) => `${value}`} // Format the label
                    />
                  )}
                </Bar>
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="col-span-1">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={vacantData} layout="vertical" margin={{ top: 20, right: 50, left: 10, bottom: 0 }}>
            <XAxis type="number" hide />
            <YAxis dataKey={vacantPropertyKey} type="category" width={150} />
            <Tooltip />
            <Legend />
            {sortedVacantKeys.map((status, index) => {
              // Check if this is the last stacked item
              const isLastStack = index === sortedVacantKeys.length - 1;

              return (
                <Bar
                  key={status}
                  dataKey={status}
                  stackId="a"
                  fill={`hsl(${(index * 300) / sortedVacantKeys.length}, 70%, 50%)`}>
                  {isLastStack && (
                    <LabelList
                      dataKey="total" // Use the `total` field to display the sum
                      position="right"
                      fill="#000"
                      formatter={(value: number) => `${value}`} // Format the label
                    />
                  )}
                </Bar>
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ReportBuilderCurrentTenantUnitStatus;
