import React, { useState, useMemo, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { toolLinks } from 'utils/Navigation/constants.tsx';
import { Building, LineChart, AreaChart, SquareDashedKanban } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { HoverCard } from '@/components/ui/hover-card';
import { HoverCardTrigger, HoverCardContent } from '@/components/ui/hover-card';
import { useUser } from 'contexts/UserContext';
import { getTableauDashboardCategories } from 'utils/getDashboardCategories';
import useTableauDashboardContext from 'utils/hooks/useTableauDashboardContext';

export default function SideNav({ mobile }) {
  const { user: currentUser } = useUser();
  const router = useRouter();
  const pathname = router.pathname;
  // Report Builder Feature Flag
  const showReportBuilder =
    currentUser?.views.includes('report-builder') && process.env.NEXT_PUBLIC_REPORT_BUILDER_FLAG === 'show';
  const { query } = router;
  const { tableauDashboardDispatch } = useTableauDashboardContext();

  const dashboardCategories = useMemo(() => {
    return getTableauDashboardCategories(currentUser);
  }, [currentUser?.id]);

  const handlePossibleDashboardChange = pageId => {
    tableauDashboardDispatch({
      type: 'setCurrentDashboard',
      payload: pageId,
    });
    tableauDashboardDispatch({
      type: 'setDashboardTabIndex',
      payload: 0,
    });
  };

  const resetDashboards = () => {
    tableauDashboardDispatch({
      type: 'setCurrentDashboard',
      payload: 'executiveSummary',
    });
    tableauDashboardDispatch({
      type: 'setDashboardTabIndex',
      payload: 0,
    });
  };

  useEffect(() => {
    if (query.page === 'reporting' || query.page === 'marketing' || query.page === 'leasing') {
      tableauDashboardDispatch({
        type: 'setCurrentDashboard',
        payload: query.page,
      });
      tableauDashboardDispatch({
        type: 'setDashboardTabIndex',
        payload: 0,
      });
    }
  }, []);

  return (
    <TooltipProvider>
      <div className={`row-start-1 flex flex-col ${mobile ? 'items-start' : 'items-center'}`}>
        <div className="flex flex-col items-center">
          <span className="hover:cursor-pointer">
            <Link
              href={
                currentUser?.views?.includes('dashboard')
                  ? `/dashboard/analytics?page=${dashboardCategories[0]?.pageId}`
                  : `/dashboard/properties`
              }>
              <Image
                src={`/static/images/${mobile ? 'leni-logo.svg' : 'leni-logo-small.png'}`}
                height={mobile ? 60 : 35}
                width={mobile ? 60 : 35}
                alt="Leni logo"
              />
            </Link>
          </span>
        </div>
        <div className="flex flex-col mt-5 gap-2">
          {currentUser?.views?.includes('dashboard') && (
            <HoverCard>
              <Link href={`/dashboard/analytics?page=${dashboardCategories[0]?.pageId}`}>
                <HoverCardTrigger asChild>
                  <span
                    className={`p-2 flex items-center justify-center rounded-full ${
                      pathname === '/dashboard/analytics' && 'bg-primary-800'
                    } cursor-pointer hover:bg-primary-800 transition-all`}
                    onClick={() => {
                      tableauDashboardDispatch({
                        type: 'setCurrentDashboard',
                        payload: dashboardCategories[0]?.pageId,
                      });
                      tableauDashboardDispatch({
                        type: 'setDashboardTabIndex',
                        payload: 0,
                      });
                    }}>
                    <LineChart height={26} width={26} />
                  </span>
                </HoverCardTrigger>
              </Link>
              <HoverCardContent
                side="right"
                align="start"
                className="flex flex-col bg-primary-800 text-white-50 border-none p-0 rounded-lg">
                {dashboardCategories.map((category, index) => (
                  <Link key={`${category.pageId}-hover-card`} href={`/dashboard/analytics?page=${category.pageId}`}>
                    <span
                      className={`${
                        query?.page === category.pageId ? 'bg-primary-700' : 'bg-primary-800'
                      } py-2 px-3 cursor-pointer hover:bg-primary-700 transition-all ${
                        index === 0 ? 'rounded-t-md' : ''
                      } ${index === dashboardCategories.length - 1 ? 'rounded-b-md' : ''}`}
                      onClick={() => handlePossibleDashboardChange(category.pageId)}>
                      {category.title}
                    </span>
                  </Link>
                ))}
              </HoverCardContent>
            </HoverCard>
          )}
          {showReportBuilder && (
            <Tooltip>
              <Link href="/dashboard/report-builder">
                <TooltipTrigger asChild>
                  <span
                    className={`p-2 flex items-center justify-center rounded-full ${
                      pathname === '/dashboard/report-builder' && 'bg-primary-800'
                    } cursor-pointer hover:bg-primary-800 transition-all`}>
                    <SquareDashedKanban className="rotate-180" height={26} width={26} />
                  </span>
                </TooltipTrigger>
              </Link>
              <TooltipContent side="right" className="bg-primary-800 border-none text-white-50">
                <p>Report Builder</p>
              </TooltipContent>
            </Tooltip>
          )}
          <Tooltip>
            <Link href="/dashboard/properties">
              <TooltipTrigger asChild>
                <span
                  className={`p-2 flex items-center justify-center rounded-full ${
                    pathname === '/dashboard/properties' && 'bg-primary-800'
                  } cursor-pointer hover:bg-primary-800 transition-all`}
                  onClick={resetDashboards}>
                  <Building height={26} width={26} />
                </span>
              </TooltipTrigger>
            </Link>
            <TooltipContent side="right" className="bg-primary-800 border-none text-white-50">
              <p>Properties</p>
            </TooltipContent>
          </Tooltip>
          {toolLinks
            .filter(i => !i.hide && !i.dummy && (currentUser?.views ? currentUser?.views.includes(i.pageId) : true))
            .map(navItem => (
              <Tooltip key={`${navItem.link} ${navItem.pageId}`}>
                <Link href={navItem.link}>
                  <TooltipTrigger asChild>
                    <span
                      className={`p-2 flex items-center justify-center rounded-full ${
                        pathname === navItem.link && 'bg-primary-800'
                      } cursor-pointer hover:bg-primary-800 transition-all`}
                      onClick={resetDashboards}>
                      {navItem.icon}
                    </span>
                  </TooltipTrigger>
                </Link>
                <TooltipContent side="right" className="bg-primary-800 border-none text-white-50">
                  <p>{navItem.title}</p>
                </TooltipContent>
              </Tooltip>
            ))}
        </div>
      </div>
    </TooltipProvider>
  );
}
