export const PRODUCT_NAMES = {
  ACTIVITIES_SCHEDULED: 'Activities Scheduled',
  AUTOMATED_EMAILS: 'Automated Emails',
  CREDIT_CHECKS: 'Credit Checks',
  LEASE_APPROVALS: 'Lease Approvals',
  MONTHLY_LEADS: 'Monthly Leads',
  PRICE_ADJUSTMENTS: 'Price Adjustments',
  PRICE_SEARCH: 'Get Adjustments',
  RENEWALS_APPROVED: 'Renewals Approved',
  REPORTS_GENERATED: 'Reports Generated',
  SPOT_CHECKS: 'Spot Checks',
  TRIGGERS: 'Triggers',
  LENIQ: 'Leni Questions',
  DATA_PROCESSED: 'Files and Data Processed',
  UNITS_MANAGEMENT: 'Units Under Management',
  PROPERTIES_MANAGEMENT: 'Properties Under Management',
  USERS_ORGANIZATION: 'Users Under Organization',
  USERS_WITH_CONVERT: 'Users With Convert',
  USERS_WITH_PRICE: 'Users With Price',
  USERS_WITH_ANALYTICS: 'Users With Analytics',
  USERS_WITH_OWNER_ROLE: 'Users With Owner Role',
  USER_WITH_USER_MANAGER_ROLE: 'Users With User Manager Role',
  USERS_WITH_ASSET_MANAGEMENT_ROLE: 'Users With Asset Management Role',
  USERS_WITH_LEASING_ROLE: 'Users With Leasing Role',
  USERS_WITH_MARKETING_ROLE: 'Users With Marketing Role',
  USERS_WITH_REVENUE_MANAGEMENT_ROLE: 'Users With Revenue Management Role',
  USERS_WITH_PROPERTY_MANAGEMENT_ROLE: 'Users With Property Management Role',
  USERS_WITH_OTHER_ROLE: 'Users With Other Role',
  CURRENT_PLAN_BY_ORGANIZATION: 'Current Plan By Organization',
  OVERAGE_BY_ORGANIZATION: 'Overage By Organization',
  DASHBOARD_VIEWS: 'Dashboard Views',
  DASHBOARDS_UNDER_ORGANIZATION: 'Dashboards Under Organization',
  DATA_PLUGS_BY_ORGANIZATION: 'Data Plugs By Organization',
  USER_LOGIN_COUNT: 'Users Login Count',
  EXECUTIVE_SUMMARY_DASHBOARD_TRACKER: 'Executive Tracker',
  FINANCIAL_PERFORMANCE_DASHBOARD_TRACKER: 'Financial Performance Tracker',
  MARKETING_ACTIVITY_DASHBOARD_TRACKER: 'Marketing Activity Tracker',
  OCCUPANCY_AND_LEASING_DASHBOARD_TRACKER: 'Occupancy And Leasing Tracker',
  RESIDENT_ACTIVITY_DASHBOARD_TRACKER: 'Resident Activity Tracker',
  WORK_ORDERS_DASHBOARD_TRACKER: 'Work Orders Tracker',
  REPORTING_DASHBOARD_TRACKER: 'Reporting Tracker',
  LEASING_DASHBOARD_TRACKER: 'Leasing Tracker',
  MARKETING_DASHBOARD_TRACKER: 'Marketing Tracker',
  UNIT_TURN_DASHBOARD_TRACKER: 'Unit Turn Tracker',
  BUDGET_VS_ACTUAL_DASHBOARD_TRACKER: 'Budget Vs Actual Tracker',
  CREATED_CUSTOM_REPORT_TRACKER: 'Created Custom Report Tracker',
  SHARE_CUSTOM_REPORT_TRACKER: 'Share Custom Report Tracker',
};

export const DASHBOARD_TRACKER = [
  PRODUCT_NAMES.EXECUTIVE_SUMMARY_DASHBOARD_TRACKER,
  PRODUCT_NAMES.FINANCIAL_PERFORMANCE_DASHBOARD_TRACKER,
  PRODUCT_NAMES.MARKETING_ACTIVITY_DASHBOARD_TRACKER,
  PRODUCT_NAMES.OCCUPANCY_AND_LEASING_DASHBOARD_TRACKER,
  PRODUCT_NAMES.RESIDENT_ACTIVITY_DASHBOARD_TRACKER,
  PRODUCT_NAMES.WORK_ORDERS_DASHBOARD_TRACKER,
  PRODUCT_NAMES.REPORTING_DASHBOARD_TRACKER,
  PRODUCT_NAMES.LEASING_DASHBOARD_TRACKER,
  PRODUCT_NAMES.MARKETING_DASHBOARD_TRACKER,
  PRODUCT_NAMES.UNIT_TURN_DASHBOARD_TRACKER,
  PRODUCT_NAMES.BUDGET_VS_ACTUAL_DASHBOARD_TRACKER,
];

export const DASHBOARD_NAMES = {
  [PRODUCT_NAMES.EXECUTIVE_SUMMARY_DASHBOARD_TRACKER]: 'Executive Summary',
  [PRODUCT_NAMES.FINANCIAL_PERFORMANCE_DASHBOARD_TRACKER]: 'Financial Performance',
  [PRODUCT_NAMES.MARKETING_ACTIVITY_DASHBOARD_TRACKER]: 'Marketing Activity',
  [PRODUCT_NAMES.OCCUPANCY_AND_LEASING_DASHBOARD_TRACKER]: 'Occupancy And Leasing',
  [PRODUCT_NAMES.RESIDENT_ACTIVITY_DASHBOARD_TRACKER]: 'Resident Activity',
  [PRODUCT_NAMES.WORK_ORDERS_DASHBOARD_TRACKER]: 'Work Orders',
  [PRODUCT_NAMES.REPORTING_DASHBOARD_TRACKER]: 'Marketing Surveys',
  [PRODUCT_NAMES.LEASING_DASHBOARD_TRACKER]: 'Leasing',
  [PRODUCT_NAMES.MARKETING_DASHBOARD_TRACKER]: 'Marketing',
  [PRODUCT_NAMES.UNIT_TURN_DASHBOARD_TRACKER]: 'Unit Turn',
  [PRODUCT_NAMES.BUDGET_VS_ACTUAL_DASHBOARD_TRACKER]: 'Budget Vs Actual',
};

// Enums to use for tracking each dashboard
export const IMPRESSION_DASHBOARDS_TRACKER = {
  executiveSummary: 'Executive Tracker',
  financialPerformance: 'Financial Performance Tracker',
  marketingActivity: 'Marketing Activity Tracker',
  occupancyAndLeasing: 'Occupancy And Leasing Tracker',
  residentActivity: 'Resident Activity Tracker',
  workOrders: 'Work Orders Tracker',
  reporting: 'Reporting Tracker',
  leasing: 'Leasing Tracker',
  marketing: 'Marketing Tracker',
  unit_turn: 'Unit Turn Tracker',
  budgeted_vs_actual: 'Budget Vs Actual Tracker',
} as any; // this is for dynamic page visits per dashboard
