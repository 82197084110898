import React from 'react';
import LeniQSuggestionQuestion from '../LeniQSuggestionQuestion';

interface LeniQSuggestionQuestionsTrayProps {
  questions: string[];
  isFullScreen: boolean;
  onClick: (q: string) => void;
}

const LeniQSuggestionQuestionsTray: React.FC<LeniQSuggestionQuestionsTrayProps> = ({
  questions,
  isFullScreen,
  onClick,
}) => {
  return (
    <div className={`flex ${isFullScreen ? 'flex-row' : 'flex-col'} overflow-auto py-1`}>
      {questions.map(q => {
        return <LeniQSuggestionQuestion key={q} question={q} onClick={onClick} />;
      })}
    </div>
  );
};

export default LeniQSuggestionQuestionsTray;
