import React, { useEffect, useState } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import ReportBuilderResizableItem from '../ReportBuilderResizableItem';
import useReportBuilderContext from 'utils/hooks/useReportBuilderContext';
import { ReportBuilderKPIMap } from 'data/reportBuilderData';
import { MultiSelectGridOptionWithImage } from 'src/types/Forms/Forms';

interface ReportBuilderReportBodyProps {
  parentRef: React.RefObject<HTMLElement>;
  headerRef: React.RefObject<HTMLElement>;
  reportKPIs?: MultiSelectGridOptionWithImage[];
}

const ResponsiveGridLayout = WidthProvider(Responsive) as unknown as React.FC<any>;

const ReportBuilderReportBody: React.FC<ReportBuilderReportBodyProps> = ({ parentRef, headerRef, reportKPIs }) => {
  const {
    state: { isViewingReport, generatedReportKPIs },
  } = useReportBuilderContext();

  const kpis = reportKPIs ? reportKPIs : generatedReportKPIs;

  const calculateLayout = () =>
    kpis.map(kpi => {
      const isBig =
        kpi.id === ReportBuilderKPIMap.AGING_SUMMARY ||
        kpi.id === ReportBuilderKPIMap.TENANTS_WITH_A_BALANCE ||
        kpi.id === ReportBuilderKPIMap.CURRENT_TENANT_UNIT_STATUS ||
        kpi.id === ReportBuilderKPIMap.OCCUPANCY_BY_PROPERTY ||
        kpi.id === ReportBuilderKPIMap.TOP_10_TRADE_OUT_GAINS ||
        kpi.id === ReportBuilderKPIMap.BOTTOM_10_TRADE_OUT_GAINS ||
        kpi.id === ReportBuilderKPIMap.T6_LEASE_RENEWAL_RATES_BY_PROPERTY;
      const isWide = kpi.id === ReportBuilderKPIMap.CURRENT_OCCUPANCY_TOTAL;
      return {
        i: `${kpi.id}-resizable-kpi`,
        x: kpi.x ?? 0, // Default to the first column
        y: kpi.y ?? 0, // Stack in rows
        w: kpi.w ? kpi.w : isWide || isBig ? 2 : 1, // Default width
        h: kpi.h ? kpi.h : isBig ? 3 : 2, // Default height
        minW: 1,
        maxW: 2,
        minH: 1,
        maxH: 3,
      };
    });

  const [layout, setLayout] = useState<Layout[]>(calculateLayout());
  const [gridHeight, setGridHeight] = useState(0);
  const onLayoutChange = (newLayout: Layout[]) => {
    setLayout(newLayout); // This will update the layout state but not reflect changes in order
  };

  useEffect(() => {
    if (!kpis) return;
    const newLayout = calculateLayout();
    setLayout(newLayout);
  }, [kpis]);

  useEffect(() => {
    const calculateGridHeight = () => {
      if (headerRef.current && parentRef.current) {
        const headerHeight = headerRef.current.offsetHeight;
        const parentHeight = parentRef.current.offsetHeight;
        setGridHeight(parentHeight - headerHeight - 50); // Adjust for spacing if needed
      }
    };

    // Use ResizeObserver to watch for changes in the parent container size
    const resizeObserver = new ResizeObserver(calculateGridHeight);
    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, [parentRef, isViewingReport]);

  return (
    <div className="col-span-2 p-0 relative">
      <ResponsiveGridLayout
        className="layout"
        margin={[10, 50]}
        layouts={{ lg: layout }}
        cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
        rowHeight={250}
        onLayoutChange={onLayoutChange}
        isResizable={false}
        isDraggable={false}
        style={{
          maxHeight: `${gridHeight}px`,
        }}>
        {kpis.map((kpi, index) => (
          <div key={`${kpi.id}-resizable-kpi`} data-grid={layout[index]}>
            <ReportBuilderResizableItem kpi={kpi} borderStyle="border-none" />
          </div>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
};

export default ReportBuilderReportBody;
