import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { motion } from 'framer-motion';

interface LeniQQuestionProps {
  reasoning: string;
}

const LeniQReasoningComponent: React.FC<LeniQQuestionProps> = ({ reasoning }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className="flex flex-col w-full  cursor-pointer my-2 "
      onClick={() => {
        setExpanded(v => {
          return !v;
        });
      }}>
      <div className="flex flex-row text-gray-400  text-sm hover:text-gray-600  w-fit ">
        <div>My reasoning</div>
        {expanded ? <ChevronUp height={20} width={20} /> : <ChevronDown height={20} width={20} />}
      </div>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: expanded ? 'auto' : 0,
          opacity: expanded ? 1 : 0,
        }}
        transition={{
          duration: 0.2,
          ease: 'easeInOut',
        }}
        className="overflow-hidden">
        <div
          className="my-2 p-3 bg-gray-100 border-gray-300 border rounded-md border-solid"
          dangerouslySetInnerHTML={{ __html: reasoning }}
        />
      </motion.div>
    </div>
  );
};

export default LeniQReasoningComponent;
