import { LoadingSpinner } from '@/components/ui/spinner';
import React, { useEffect, useRef, useState } from 'react';
import { UserApi } from 'services/api/user';
import { MultiSelectGridOptionWithImage, MultiSelectOption } from 'src/types/Forms/Forms';
import ReportBuilderReportHeader from 'components/ReportBuilder/ReportBuilderView/ReportBuilderReportHeader';
import ReportBuilderReportSummary from 'components/ReportBuilder/ReportBuilderView/ReportBuilderReportSummary';
import ReportBuilderReportBody from 'components/ReportBuilder/ReportBuilderView/ReportBuilderReportBody';

interface ReportBuilderShareReportContainerProps {
  reportId: string;
}

const ReportBuilderShareReportContainer: React.FC<ReportBuilderShareReportContainerProps> = ({ reportId }) => {
  const [loadingReport, setLoadingReport] = useState(false);
  const [reportTitle, setReportTitle] = useState('');
  const [reportDate, setReportDate] = useState('');
  const [reportProperties, setReportProperties] = useState<MultiSelectOption[]>([]);
  const [reportKPIs, setReportKPIs] = useState<MultiSelectGridOptionWithImage[]>([]);
  const parentRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    if (!reportId) return;
    const fetchReportData = async () => {
      setLoadingReport(true);
      try {
        const res = await UserApi.getReport(reportId);
        const { properties, reportDate, reportTitle, KPIs } = res.data;
        setReportTitle(reportTitle);
        setReportDate(reportDate);
        setReportProperties(properties);
        setReportKPIs(KPIs);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingReport(false);
      }
    };
    fetchReportData();
  }, [reportId]);

  return (
    <div ref={parentRef} className="min-w-full">
      {loadingReport && (
        <div className="flex h-screen items-center justify-center">
          <LoadingSpinner className="mr-3" height={100} width={100} />
        </div>
      )}

      {!loadingReport && (
        <div className="p-8">
          <div ref={headerRef}>
            <ReportBuilderReportHeader reportTitle={reportTitle} reportDate={reportDate} />
            <ReportBuilderReportSummary reportProperties={reportProperties} reportDate={reportDate} />
          </div>
          <ReportBuilderReportBody parentRef={parentRef} headerRef={headerRef} reportKPIs={reportKPIs} />
        </div>
      )}
    </div>
  );
};

export default ReportBuilderShareReportContainer;
