import { ReportBuilderKPIMap } from 'data/reportBuilderData';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

// Define a color palette for the bars
const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffbb28'];

// Props type for MultiBarChart
interface ReportBuilderMultiBarChartProps {
  id: string;
  data: Array<Record<string, string | number>>;
}

// Define custom label type to match Recharts LabelList expectations
interface CustomLabelProps {
  x?: string | number;
  y?: string | number;
  width?: string | number;
  value?: string | number;
}

const formatCurrency = (value: number) => {
  return `$${value}`;
};

// MultiBarChart Component
const ReportBuilderMultiBarChart: React.FC<ReportBuilderMultiBarChartProps> = ({ id, data }) => {
  // Empty data should be handled in outer component ReportBuilderResizableItem for debugging purposes
  // Guard clause should be if Tableau returned an error (status code 429, etc)
  if (!Array.isArray(data) || data.length === 0) {
    return;
  }

  // Custom Label component
  const CustomLabel: React.FC<CustomLabelProps> = ({ x, y, width, value }) => {
    if (!x || !y || !width || value === undefined) return null;

    // Convert x, y, and width to numbers if necessary
    const numericX = typeof x === 'string' ? parseFloat(x) : x;
    const numericY = typeof y === 'string' ? parseFloat(y) : y;
    const numericWidth = typeof width === 'string' ? parseFloat(width) : width;

    if (isNaN(numericX) || isNaN(numericY) || isNaN(numericWidth)) return null;

    return (
      <text
        x={numericX + numericWidth + 3} // Center horizontally
        y={numericY - 7} // Adjust position to be above the bar
        fill="#333" // Label color
        textAnchor="middle"
        transform={`rotate(270, ${numericX + numericWidth / 2}, ${numericY - 10})`}
        fontSize="10px">
        {id === ReportBuilderKPIMap.IN_PLACE_RENT ? `$ ${value}` : `${value}`}
      </text>
    );
  };

  const xKey = Object.keys(data[0])[0];
  const groupKeys = Object.keys(data[0]).slice(1);

  // Calculate the maximum value in the dataset to set the Y-axis scale
  const allValues = data.flatMap(entry => groupKeys.map(key => entry[key] as number));
  const maxValue = Math.max(...allValues);
  const adjustedMax = Math.ceil(maxValue * 1.4); // Add 40% headroom

  return (
    <ResponsiveContainer width="100%" height="90%">
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 50 }} // Adjust bottom margin to fit X-axis label and legend
        barCategoryGap="20%" // Space between bar groups
        barGap={5} // Space between individual bars
      >
        <XAxis
          dataKey={xKey}
          label={{
            value: id === ReportBuilderKPIMap.IN_PLACE_RENT ? 'Number of Bedrooms' : undefined,
            position: 'insideBottom',
            dy: 20, // Move the label further down from the ticks
            fontSize: '12px',
            fill: '#333',
          }}
        />
        <YAxis
          domain={[0, adjustedMax]}
          hide={
            id === ReportBuilderKPIMap.T12_WORK_ORDER_PRIORITY_TRENDS ||
            id === ReportBuilderKPIMap.T12_MARKETING_ACTIVITY
          }
          tickFormatter={formatCurrency}
        />{' '}
        {/* Set domain to include headroom */}
        <Tooltip />
        <Legend
          verticalAlign={id === ReportBuilderKPIMap.IN_PLACE_RENT ? 'top' : 'bottom'}
          height={id === ReportBuilderKPIMap.IN_PLACE_RENT ? 30 : 0}
        />{' '}
        {/* Adjust the legend position */}
        {/* Render Bars dynamically with unique colors */}
        {groupKeys.map((key, index) => (
          <Bar key={key} dataKey={key} fill={COLORS[index % COLORS.length]}>
            <LabelList
              dataKey={key}
              formatter={formatCurrency}
              content={props => <CustomLabel {...props} />} // Use custom label
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ReportBuilderMultiBarChart;
