import { ReportBuilderKPIMap } from 'data/reportBuilderData';
import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

// Define a color palette for the lines
const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffbb28'];

// Props type for MultiLineChart
interface ReportBuilderMultiLineChartProps {
  id: string;
  data: Array<Record<string, string | number>>;
}

const formatPercentage = (value: number) => {
  return `${value}%`;
};

const formatAxisNumberLabel = (value: number): string => {
  if (value >= 1000000000) {
    const billions = Math.round(value / 1000000000);
    return `${billions.toFixed(2)} Bill`;
  } else if (value >= 1000000) {
    const millions = value / 1000000; // Keep 2 decimal places for values in millions
    return `${millions.toFixed(2)} Mill`;
  }
  return value.toLocaleString('en-US'); // Default formatting for smaller values
};

// MultiLineChart Component
const ReportBuilderMultiLineChart: React.FC<ReportBuilderMultiLineChartProps> = ({ data, id }) => {
  // Empty data should be handled in outer component ReportBuilderResizableItem for debugging purposes
  // Guard clause should be if Tableau returned an error (status code 429, etc)
  if (!Array.isArray(data) || data.length === 0) {
    return;
  }
  // The x-axis key is assumed to be the first key in the data
  const xKey = Object.keys(data[0])[0];
  // Line keys are all other keys excluding the x-axis key
  const lineKeys = Object.keys(data[0]).slice(1);

  // Identify the key for "Collection %" (if it exists)
  const collectionKey = lineKeys.find(key => key === 'Collection %');

  // Filter out "Collection %" for the primary Y-axis if id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION
  const primaryLineKeys =
    id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION ? lineKeys.filter(key => key !== collectionKey) : lineKeys;

  return (
    <ResponsiveContainer width="100%" height="80%">
      <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey={xKey} />
        <YAxis
          tickFormatter={id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION ? formatAxisNumberLabel : formatPercentage}
        />

        {/* Add secondary Y-axis only if id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION */}
        {id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION && (
          <YAxis
            yAxisId="right"
            orientation="right"
            tick={false} // Hide ticks
            axisLine={false} // Hide the axis line
            tickFormatter={
              id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION ? formatAxisNumberLabel : formatPercentage
            }
          />
        )}

        <Tooltip
          formatter={id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION ? formatAxisNumberLabel : formatPercentage}
        />
        <Legend />

        {/* Render primary lines dynamically with unique colors */}
        {primaryLineKeys.map((key, index) => (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={COLORS[index % COLORS.length]}
            strokeWidth={2}
            dot={false}>
            {id !== ReportBuilderKPIMap.COMPARITIVE_RENT_COLLECTIONS && (
              <LabelList
                dataKey={key}
                content={props => {
                  const { x, y, value, index } = props;
                  const isLastPoint = index === data.length - 1;
                  return isLastPoint && x !== undefined && y !== undefined ? (
                    <text
                      x={x}
                      y={
                        typeof y === 'number'
                          ? id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION
                            ? y + 15
                            : y - 5
                          : 0
                      }
                      fill="#000"
                      textAnchor="middle"
                      fontSize="14px">
                      {id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION
                        ? formatAxisNumberLabel(value as number)
                        : formatPercentage(value as number)}
                    </text>
                  ) : null;
                }}
              />
            )}
          </Line>
        ))}

        {/* Add a separate line for "Collection %" on the secondary Y-axis if id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION */}
        {id === ReportBuilderKPIMap.CURRENT_RENT_COLLECTION && collectionKey && (
          <Line
            yAxisId="right"
            type="monotone"
            dataKey={collectionKey}
            stroke={COLORS[primaryLineKeys.length % COLORS.length]}
            strokeWidth={2}
            strokeDasharray="5 5"
            dot={false}>
            <LabelList
              dataKey={collectionKey}
              content={props => {
                const { x, y, value, index } = props;
                const isLastPoint = index === data.length - 1;
                return isLastPoint && x !== undefined && y !== undefined ? (
                  <text x={x} y={typeof y === 'number' ? y - 15 : 0} fill="#000" textAnchor="middle" fontSize="14px">
                    {formatPercentage(value as number)}
                  </text>
                ) : null;
              }}
            />
          </Line>
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ReportBuilderMultiLineChart;
