import { ReportBuilderKPIMap } from 'data/reportBuilderData';
import React from 'react';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface ReportBuilderHorizontalBarTableChartProps {
  id: string;
  data: any[];
}

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffbb28'];

const transformData = (data: Record<string, string>): Array<{ name: string; value: number }> => {
  return Object.entries(data)
    .filter(([key]) => key !== 'Aging Days') // Exclude "Aging Days" key
    .map(([key, value]) => ({
      name: key, // Property name
      value: value === 'N/A' ? 0 : Number(value.substring(1).replaceAll(',', '')), // Convert value to a number
    }));
};

const ReportBuilderHorizontalBarTableChart: React.FC<ReportBuilderHorizontalBarTableChartProps> = ({ id, data }) => {
  // Empty data should be handled in outer component ReportBuilderResizableItem for debugging purposes
  // Guard clause should be if Tableau returned an error (status code 429, etc)
  if (!Array.isArray(data) || data.length === 0) {
    return;
  }
  const colNames = Object.values(data.map(item => item['Aging Days']));

  const formatToDollarString = (value: number) =>
    `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

  return (
    <div className="w-full h-[80%] grid grid-cols-4 grid-rows-[50px,1fr]">
      {colNames.map(item => (
        <span
          key={item}
          className="col-span-1 border-b-[1px] border-gray-400 border-r-[1px] last:border-r-0 flex items-center justify-center">
          {item.toString()}
        </span>
      ))}
      {data.map((category, index) => {
        const chartData = transformData(category);
        return (
          <div key={index} className="col-span-1 border-r-[1px] last:border-r-0 border-gray-400">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                layout="vertical"
                margin={{ top: 20, right: 70, left: index === 0 ? 10 : 20, bottom: 20 }}>
                <XAxis type="number" hide />
                <YAxis dataKey="name" type="category" width={80} hide={index !== 0} />
                <Tooltip
                  formatter={id === ReportBuilderKPIMap.AGING_SUMMARY ? formatToDollarString : undefined}
                  wrapperStyle={{ zIndex: 9999 }}
                />
                <Bar dataKey="value" fill="#8884d8">
                  {chartData.map((entry, i) => (
                    <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} /> // Assign unique color
                  ))}
                  {id === ReportBuilderKPIMap.AGING_SUMMARY ? (
                    <LabelList
                      dataKey="value"
                      position="right"
                      formatter={id === ReportBuilderKPIMap.AGING_SUMMARY ? formatToDollarString : undefined}
                      fill="#000"
                    />
                  ) : (
                    <LabelList dataKey="value" position="right" fill="#000" />
                  )}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        );
      })}
    </div>
  );
};

export default ReportBuilderHorizontalBarTableChart;
