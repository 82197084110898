import React from 'react';

interface ReportBuilderHeatMapChartProps {
  id: string;
  data: Array<Record<string, string | number>>;
}

const ReportBuilderHeatMapChart: React.FC<ReportBuilderHeatMapChartProps> = ({ id, data }) => {
  // Empty data should be handled in outer component ReportBuilderResizableItem for debugging purposes
  // Guard clause should be if Tableau returned an error (status code 429, etc)
  if (!Array.isArray(data) || data.length === 0) {
    return;
  }

  // Extract column names from the first object
  const columnCount = Object.keys(data[0]).length;
  const columnNames = Object.keys(data[0]);
  return (
    <div
      id={id}
      className={`w-full h-[80%] grid`}
      style={{
        gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))`,
      }}>
      {/* Render column headers */}
      {columnNames.map((colName, colIndex) => (
        <div
          key={colIndex}
          className="col-span-1 flex items-center justify-center font-bold border-r-[1px] border-b-[1px] border-gray-400">
          {colName}
        </div>
      ))}

      {/* Render rows and cells */}
      {data.map((dataRow, rowIndex) =>
        Object.values(dataRow).map((rowValue, cellIndex) => {
          const isNA = rowValue === 'N/A';
          const percentageMatch = rowValue.toString().match(/^(\d+(\.\d+)?)%$/);
          const opacity = percentageMatch ? parseFloat(percentageMatch[1]) / 100 : 0;

          return (
            <div
              key={`${rowIndex}-${cellIndex}`}
              className="col-span-1 border-gray-400 flex justify-center items-center border-r-[1px] border-b-[1px] text-center"
              style={{
                backgroundColor: isNA ? 'white' : `rgba(255, 180, 0, ${opacity})`, // Light yellow with calculated opacity
              }}>
              {rowValue}
            </div>
          );
        })
      )}
    </div>
  );
};

export default ReportBuilderHeatMapChart;
